import type { NextRouter } from 'next/router';

const storeBackUrl = (router: NextRouter) => {
  let backUrl;
  if (router.isReady) {
    backUrl = router.query.backUrl;
  } else {
    const params = new URLSearchParams(window.location.search);
    backUrl = params.get('backUrl');
  }
  if (typeof backUrl === 'string') {
    localStorage.setItem('backUrl', backUrl);
  }
};

const restoreBackUrl = () => {
  const backUrl = localStorage.getItem('backUrl');
  localStorage.removeItem('backUrl');
  return backUrl;
};

const removeBackUrl = () => {
  localStorage.removeItem('backUrl');
};

const storeEmail = (email: string) => {
  localStorage.setItem('emailForSignIn', email);
};

const restoreEmail = () => {
  const email = localStorage.getItem('emailForSignIn');
  localStorage.removeItem('emailForSignIn');
  return email;
};

export { storeBackUrl, restoreBackUrl, removeBackUrl, storeEmail, restoreEmail };
