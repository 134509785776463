// eslint-disable-next-line import/no-extraneous-dependencies
import { EmailAuthProvider, GoogleAuthProvider } from '@firebase/auth';
import { MICROSOFT_PROVIDER_ID } from '@/lib/firebase-client';
// ===== User Properties =====
export const UP_LAST_SIGN_IN_AT = '最終ログイン日';
export const UP_SERVICE_NAMES_IN_USE = '利用中のサービス名';
export const UP_NUMBER_OF_SERVICES_IN_USE = 'kintoneApp利用サービス数';
export const UP_FIRST_TIME_USE_SERVICES = '利用開始時のサービス名';

// ===== Event Names =====
export const EN_CLICK_SIGN_IN = 'AC_ログインする';
export const EN_PAGE_VIEW_APPS = 'AC_kintoneApp トップを閲覧する';
export const EN_PAGE_VIEW_PROFILE_SETTINGS = 'AC_プロフィール設定を閲覧する';
export const EN_PAGE_VIEW_SUB_ACCOUNT_SETTINGS = 'AC_サブ管理者設定を閲覧する';
export const EN_PAGE_VIEW_SUB_ACCOUNT_INVITATIONS = 'AC_招待の承諾を閲覧する';
export const EN_PAGE_VIEW_LOGIN = 'AC_ログイン画面を閲覧する';
export const EN_CLICK_PROFILE_PREVIEW = 'AC_プロフィールのプレビューを確認する';
export const EN_CLICK_SAVE_PROFILE = 'AC_プロフィール設定を保存する';
export const EN_CLICK_ADD_SUB_ACCOUNT = 'AC_サブ管理者を追加する';
export const EN_CLICK_DELETE_SUB_ACCOUNT = 'AC_サブ管理者を削除する';
export const EN_CLICK_SWAP_OWNER = 'AC_サブ管理者を管理者にする';
export const EN_CLICK_APPROVE_INVITATION = 'AC_招待の承諾を承認する';
export const EN_CLICK_REJECT_INVITATION = 'AC_招待の承諾を辞退する';
export const EN_CLICK_RESOURCES = 'AC_「もっと便利に」をクリックする';
export const EN_CLICK_OPEN_WHAT_IS_KINTONEAPP_AUTH = 'AC_Toyokumo kintoneApp認証をクリックする';
export const EN_PAGE_VIEW_INTRODUCTION_FORMBRIDGE = 'AC_無料お試し申し込みフォーム(FormBridge)を閲覧する';
export const EN_PAGE_VIEW_INTRODUCTION_KVIEWER = 'AC_無料お試し申し込みフォーム(kViewer)を閲覧する';
export const EN_PAGE_VIEW_INTRODUCTION_KMAILER = 'AC_無料お試し申し込みフォーム(kMailer)を閲覧する';
export const EN_PAGE_VIEW_INTRODUCTION_PRINTCREATOR = 'AC_無料お試し申し込みフォーム(PrintCreator)を閲覧する';
export const EN_PAGE_VIEW_INTRODUCTION_DATACOLLECT = 'AC_無料お試し申し込みフォーム(DataCollect)を閲覧する';
export const EN_PAGE_VIEW_INTRODUCTION_KBACKUP = 'AC_無料お試し申し込みフォーム(kBackup)を閲覧する';
export const EN_SUBMIT_FORM_TRIAL = 'AC_無料お試し申し込みを完了する';
export const EN_PAGE_VIEW_ORDER_FORMBRIDGE = 'AC_有料コース申し込みフォーム(FormBridge)を閲覧する';
export const EN_PAGE_VIEW_ORDER_KVIEWER = 'AC_有料コース申し込みフォーム(kViewer)を閲覧する';
export const EN_PAGE_VIEW_ORDER_KMAILER = 'AC_有料コース申し込みフォーム(kMailer)を閲覧する';
export const EN_PAGE_VIEW_ORDER_PRINTCREATOR = 'AC_有料コース申し込みフォーム(PrintCreator)を閲覧する';
export const EN_PAGE_VIEW_ORDER_DATACOLLECT = 'AC_有料コース申し込みフォーム(DataCollect)を閲覧する';
export const EN_PAGE_VIEW_ORDER_KBACKUP = 'AC_有料コース申し込みフォーム(kBackup)を閲覧する';
export const EN_SUBMIT_FORM_ORDER = 'AC_有料コースの申し込みを完了する';
export const EN_PAGE_VIEW_UPGRADE_FORMBRIDGE = 'AC_アップグレード申し込みフォーム(FormBridge)を閲覧する';
export const EN_PAGE_VIEW_UPGRADE_KVIEWER = 'AC_アップグレード申し込みフォーム(kViewer)を閲覧する';
export const EN_PAGE_VIEW_UPGRADE_KMAILER = 'AC_アップグレード申し込みフォーム(kMailer)を閲覧する';
export const EN_PAGE_VIEW_UPGRADE_PRINTCREATOR = 'AC_アップグレード申し込みフォーム(PrintCreator)を閲覧する';
export const EN_PAGE_VIEW_UPGRADE_DATACOLLECT = 'AC_アップグレード申し込みフォーム(DataCollect)を閲覧する';
export const EN_PAGE_VIEW_UPGRADE_KBACKUP = 'AC_アップグレード申し込みフォーム(kBackup)を閲覧する';
export const EN_SUBMIT_FORM_UPGRADE = 'AC_アップグレードの申し込みを完了する';

// ===== Event Properties =====
export const EP_URL = 'URL';
export const EP_PAGE_PATH = 'ページパス';
export const EP_PAGE_TITLE = 'ページタイトル';
export const EP_SERVICE_NAME = 'サービス名';
export const EP_EVENT_OCCURRED_AT = 'イベント発生日時';
export const EP_SIGN_IN_METHOD = 'ログイン方法';
export const EP_APPLICATION_SERVICE = '申し込みサービス';

// ===== Values of Event Properties =====
export const EPV_SERVICE_NAME_ACCOUNT = 'Toyokumo kintoneApp アカウント';
const EPV_SIGN_IN_METHOD: Record<string, string> = {
  [EmailAuthProvider.PROVIDER_ID]: 'メールリンク',
  [GoogleAuthProvider.PROVIDER_ID]: 'Google',
  [MICROSOFT_PROVIDER_ID]: 'Microsoft',
};

export const getSignInMethodName = (providerId: string): string | undefined => EPV_SIGN_IN_METHOD[providerId];
