import { NEXT_PUBLIC_TRUSTED_DOMAIN } from '@/config/env-client';

const isSubDomain = (hostname: string, domain: string): boolean => hostname.endsWith(`.${domain}`);

const isTrustedRedirectHost = (url: string): boolean => {
  const u = new URL(url);
  return isSubDomain(u.hostname, NEXT_PUBLIC_TRUSTED_DOMAIN);
};

export { isTrustedRedirectHost, isSubDomain };
