export const dictionary = {
  ja: {
    // 単語
    account: 'アカウント',
    login: 'ログイン',
    logout: 'ログアウト',
    termsOfService: '利用規約',
    privacyPolicy: 'プライバシーポリシー',
    emailAddress: 'メールアドレス',
    send: '送信',
    cancel: 'キャンセル',
    back: '戻る',
    select: '選択',
    service: 'サービス',
    serviceList: 'サービス一覧',
    settings: '設定',
    profileSettings: 'プロフィール設定',
    subAdminSettings: 'サブ管理者設定',
    invitations: '招待の承諾',
    launch: '開く',
    all: 'すべて',
    providers: '提供元',
    search: '検索',
    userPage: 'ユーザーページ',
    manageServices: 'サービス管理',
    preview: 'プレビュー',
    save: '保存',
    userPagePerProvider: '提供元別ユーザーページ',
    help: 'ヘルプ',
    image: '画像',
    delete: '削除',
    success: '成功',
    warn: '警告',
    companyOrganizationName: '会社/組織名',
    addLink: 'リンク追加',
    editLink: 'リンク編集',
    text: 'テキスト',
    link: 'リンク先',
    TRIAL: '無料お試し',
    LIGHT: 'ライト',
    STANDARD: 'スタンダード',
    PREMIUM: 'プレミアム',
    PROFESSIONAL: 'プロフェッショナル',
    ENTERPRISE: 'エンタープライズ',
    'input.placeholder.text': 'テキストを入力',
    'input.placeholder.anyUrl': 'URL、メールアドレス、電話番号を入力',
    // 文章
    'login.google': 'Googleでログイン',
    'login.ms': 'Microsoftアカウントでサインイン',
    'login.email': 'メールアドレスでログイン',
    'login.toSignIn': 'ログインすることで',
    'login.and': 'と',
    'login.agreeWithTermsAndPolicy': 'に同意したものとみなします。',
    'login.email.description': 'このメールアドレス宛にログイン用URLを送信します。',
    'login.emailSend': 'メールを確認してください',
    'login.emailSend.description':
      '{{ email }}宛にメールを送信しました。メールの記載のURLをクリックするとログインできます。',
    'login.emailVerifyRequired':
      'メールアドレスの所有確認のために {{ email }} 宛にメールを送信しました。確認が完了次第、この画面で自動的にログインが行われます。',
    'login.accountLink': 'アカウントを紐付ける',
    'login.accountLink.title': '登録済みのメールアドレスです',
    'login.accountLink.google.ms':
      'はGoogleアカウントとして登録されています。このメールアドレスをMicrosoftアカウントとして登録するにはGoogleアカウントと紐付けてください。',
    'login.accountLink.sendMail': 'メールを送信する',
    'login.accountLink.sentMailForLink':
      'ログイン用のメールを送信しました。送られたメールのリンクをコピーしてここに貼り付けてください。',
    'login.accountLink.mail.ms':
      'はメールによるログインで登録されています。このメールアドレスをMicrosoftアカウントとして登録するには、再度メールによるログインを実行してアカウントを紐づける必要があります。',
    'login.EnterLoginEmail': 'ログインするためのemailを入力してください。',
    'login.again': '再度ログインを行ってください。',
    'login.usedLoginLink': '既に使用されたログインリンクは使用できません。',
    'login.loginLinkExpired': 'ログインリンクの有効期限が切れています。',
    'login.invalidEmail': 'メールアドレスに誤りがあります。',
    'login.accountLink.unsupportedLogin': 'サポートしていないログイン方式です。',
    'login.accountLink.failedLogin': 'ログインに失敗しました。始めから操作をしてください。',
    'app.welcome.title': 'Toyokumo kintoneAppへようこそ',
    'app.welcome.explain1': '業務改善をもっと手軽に。もっと簡単に。',
    'app.welcome.explain2': 'サービスを組み合わせることで簡単にシステムを構築・拡張できます。',
    'app.introductionText.fb':
      'kintoneと連携するWebフォームを簡単につくれるサービスです。kintoneのアカウントを持たないユーザーが入力したデータをkintoneに保存する機能を追加できます。',
    'app.introductionText.kv':
      'kintoneと連携するWebページを簡単につくれるサービスです。kintoneのアカウントを持たないユーザーにkintone上のデータを閲覧させる機能を追加できます。',
    'app.introductionText.km':
      'kintoneと連携するメールを簡単に自動送信できるサービスです。kintone上のデータを引用しながらメールを生成して送信する機能を追加できます。',
    'app.introductionText.pc':
      'kintoneと連携する帳票を簡単につくれるサービスです。kintone上のデータを使って帳票を出力する機能を追加できます。',
    'app.introductionText.dc':
      'kintone上のデータの集計が簡単にできるサービスです。kintone複数アプリ間のデータを収集・計算・加工する機能を追加できます。',
    'app.introductionText.kb':
      'kintone上のデータと添付ファイルのバックアップを簡単にできるサービスです。標準機能ではできない、レコード・フィールド誤削除のリカバリーができます。',
    'app.goToWebsite': 'サービスサイトへ',
    'app.free30DayTrial': '30日間無料お試し',
    'app.freeTrialEndAt': ' {{ trialExpiresAt }} まで',
    'app.freeTrialExpired': 'お試し期限切れ',
    'app.resources': 'もっと便利に',
    'app.selectAccount.title': 'サービス利用者の選択',
    'app.selectAccount.selectingOwn': '自分が所有するサービスを選択中',
    'app.selectAccount.selectingSub': '{{ email }}のサブ管理者を選択中',
    'app.selectAccount.description': '管理者として利用するか、サブ管理者として利用するかを選んでください。',
    'app.selectAccount.choice.own': '自分が所有するサービスを利用',
    'app.selectAccount.choice.sub': 'サブ管理者として利用',
    'app.selectAccount.inviter': '招待された管理者',
    'app.profile.welcome.title': '他の人があなたのフォーム/ビューを見つけやすくなります',
    // 空文字を設定するとnext-g11nのtranslationがエラーとなったため変数のみ記述し空文字を与えている
    'app.profile.welcome.explain1': '{{ explain }}',
    'app.profile.welcome.explain2':
      'ではフォーム/ビューを提供元メニューから絞込めます。プロフィールを設定すると、あなたがアクセス許可したユーザーの提供元メニューにあなたの会社/組織名が追加されます。',
    'app.profile.welcome.explain3':
      'FormBridge/kViewerで、フォーム/ビューをToyokumo kintoneApp認証によりユーザー管理する必要があります。',
    'app.profile.textarea.explain': '業務指示等を自由に入力',
    'app.profile.accessible.title': 'あなたがアクセス許可したフォーム/ビューだけが登録したユーザーに見えます。',
    'app.profile.noAccessible.title': 'あなたが作成したフォーム/ビューはありません。',
    'app.profile.noAccessible.explain':
      'FormBridge/kViewerで作成したアクセス制限付フォーム/ビューがここに表示されます。',
    'app.profile.confirmPageChange': 'このページを離れますか？\n行った変更が保存されない可能性があります。',
    'user.profile.companyOrganizationName.explain1': '入力して保存すると、あなたがアクセス許可したユーザーから見た',
    'user.profile.companyOrganizationName.explain2': 'の提供元メニューに追加されます。',
    'user.profile.successfullySaved': 'プロフィールを保存しました。',
    'user.profile.copyLink': 'リンクをコピー',
    'user.profile.linkCopied': 'リンクをコピーしました。',
    'user.profile.saveAccountFailed': 'アカウント情報の保存に失敗しました',
    'user.noProvider': '表示する提供元がありません',
    'user.welcome.title': 'URLを覚えていなくても、一覧からページを探せます',
    'user.welcome.explain': '提供元の会社/組織名で絞り込めば、素早く目的のページが見つかります。',
    'user.noView.heading': 'アクセス許可されたページがありません',
    'user.noView.explain': 'あなたにアクセス許可されたページがここに表示されます。',
    'user.noMatchingViewForSearch': '検索に一致するページがありません。',
    'user.confirm.public.title': '会社/組織名が提供元メニューに追加されます',
    'user.confirm.public.explain':
      'あなたがアクセス許可したユーザーから見たユーザーページの提供元メニューにあなたの会社/組織名が追加され、会社/組織名でフォーム/ビューを絞込めるようになります。よろしいですか？',
    'user.confirm.private.title': '会社/組織名が提供元メニューから削除されます',
    'user.confirm.private.explain':
      'あなたがアクセス許可したユーザーから見たユーザーページの提供元メニューからあなたの会社/組織名が削除され、会社/組織名でフォーム/ビューを絞込めなくなります。よろしいですか？',
    'user.confirm.private.caution':
      'あなたの会社/組織名が提供元メニューから削除されても、あなたのフォーム/ビューは公開され続けます。',
    'validation.maxImageLimit.title': '画像の読み込みに失敗しました',
    'validation.maxImageLimit.explain': '画像は{{ maxImageSize }}以下である必要があります',
    'validation.required': '必須入力項目です',
    'validation.invalidEmail': 'メールアドレスの形式が正しくありません',
    'validation.invalidUrl': 'URLの形式が正しくありません',
    'validation.maxTextLimit': '{{ length }}文字以内で入力してください',
  },
  en: {
    // 単語
    account: 'Account',
    login: 'Sign In',
    logout: 'Sign Out',
    termsOfService: 'the Terms of Service',
    privacyPolicy: 'the Privacy Policy',
    emailAddress: 'Email Address',
    send: 'Send',
    cancel: 'Cancel',
    back: 'Go Back',
    select: 'Select',
    service: 'Services',
    serviceList: 'All Services',
    settings: 'Settings',
    profileSettings: 'Profile Settings',
    subAdminSettings: 'Sub-admin Settings',
    invitations: 'Invitations',
    launch: 'Launch',
    all: 'All',
    search: 'Search',
    userPage: 'User Page',
    manageServices: 'Manage Services',
    providers: 'Providers',
    preview: 'Preview',
    save: 'Save',
    userPagePerProvider: 'User Page per provider',
    help: 'Help',
    image: 'Image',
    delete: 'Delete',
    success: 'Success',
    warn: 'Warning',
    companyOrganizationName: 'Company/organization name',
    addLink: 'Add link',
    editLink: 'Edit link',
    text: 'Text',
    link: 'Link',
    TRIAL: 'Free Trial',
    LIGHT: 'Light',
    STANDARD: 'Standard',
    PREMIUM: 'Premium',
    PROFESSIONAL: 'Professional',
    ENTERPRISE: 'Enterprise',
    'input.placeholder.text': 'Text',
    'input.placeholder.anyUrl': 'URL, email or phone',
    // 文章
    'login.google': 'Sign in with Google',
    'login.ms': 'Sign in with Microsoft',
    'login.email': 'Sign in with email',
    'login.toSignIn': 'You accept and agree to be bound by ',
    'login.and': ' and ',
    'login.agreeWithTermsAndPolicy': ' by signing in to Toyokumo kintoneApp.',
    'login.email.description':
      'Enter your email address to send an email containing a URL which you can use for sign in.',
    'login.emailSend': 'Check Your Email',
    'login.emailSend.description':
      'An email has been sent to {{ email }}. You can sign in by clicking the URL contained in the email.',
    'login.emailVerifyRequired':
      "An email has been sent to {{ email }} to verify your email address. As soon as it’s verified, you'll be automatically signed in on this page.",
    'login.accountLink': 'Link your account',
    'login.accountLink.title': 'Email is already registered',
    'login.accountLink.google.ms':
      ' has already been registered as a Google account. To register this email address as a Microsoft account, please link it with a Google account.',
    'login.accountLink.sendMail': 'Send an Email',
    'login.accountLink.sentMailForLink':
      'We have sent an email for signing in. Please copy the link from the sent email and paste it here.',
    'login.accountLink.mail.ms':
      ' has already been registered with email sign-in. To register this email address as a Microsoft account, you need to sign in via email again and link the account.',
    'login.accountLink.unsupportedLogin': 'unsupported sing-in method.',
    'login.accountLink.failedLogin': 'failed to sign in. Please start from the beginning.',
    'login.EnterLoginEmail': 'Please enter your email to sign in.',
    'login.again': 'Please sign in again.',
    'login.usedLoginLink': 'You cannot use a already used sign-in link. ',
    'login.loginLinkExpired': 'the sing-in link has expired. ',
    'login.invalidEmail': 'email address is incorrect. ',
    'app.welcome.title': 'Welcome to Toyokumo kintoneApp',
    'app.welcome.explain1': 'Business improvement becomes simpler and easier than ever.',
    'app.welcome.explain2': 'Extend your system in combination with our services to scale your business.',
    'app.introductionText.fb':
      'Create web forms that work with Kintone, fast and easy. Submitted information from non-Kintone users can be saved in your Kintone.',
    'app.introductionText.kv':
      'Create web pages that work with Kintone, fast and easy. Your Kintone data can be published for non-Kintone users.',
    'app.introductionText.km':
      'Generate emails that work with Kintone, fast and easy. Your Kintone data can be inserted anywhere in your emails.',
    'app.introductionText.pc':
      'Create reports and forms that work with Kintone, fast and easy. Your Kintone data can be exported to printable file formats.',
    'app.introductionText.dc':
      'Aggregate your Kintone data, fast and easy. Your data from multiple Kintone applications can be collected and aggregated.',
    'app.introductionText.kb':
      'Backup your Kintone data, fast and easy. Your lost data by mistake can be restored field by field.',
    'app.goToWebsite': 'Go to Website',
    'app.free30DayTrial': 'Free 30-Day Trial',
    'app.freeTrialEndAt': ' ends on {{ trialExpiresAt }}',
    'app.freeTrialExpired': 'Free Trial has ended',
    'app.resources': 'Resources',
    'app.selectAccount.title': 'Select who to use the service',
    'app.selectAccount.selectingOwn': 'Using services you own',
    'app.selectAccount.selectingSub': 'Using as the sub-administrator whose administrator is {{ email }}',
    'app.selectAccount.description':
      'Select whether to use as an administrator or a sub-administrator. If you select a sub-administrator,  select the administrator who invited you to be a sub-administrator.',
    'app.selectAccount.choice.own': 'Use services you own',
    'app.selectAccount.choice.sub': 'Use as a sub-administrator',
    'app.selectAccount.inviter': 'The administrator who invited you',
    'app.profile.welcome.title': 'Make it easy for others to find your forms/views.',
    'app.profile.welcome.explain1': 'On the ',
    'app.profile.welcome.explain2':
      ', users can narrow down forms/views by providers. If you set up your profile, the users you’ve given access to will find your company/organization name listed on the Providers menu.',
    'app.profile.welcome.explain3':
      'We need to manage form/view users using Toyokumo kintoneApp Authentication in FormBridge/kViewer.',
    'app.profile.textarea.explain': 'Text goes here (e.g., business instructions).',
    'app.profile.accessible.title': 'Only the forms/views you’ve given access to will be visible to registered users.',
    'app.profile.noAccessible.title': 'There are no forms/views you’ve created.',
    'app.profile.noAccessible.explain':
      'Access-restricted forms/views created with FormBridge/kViewer would be listed here.',
    'app.profile.confirmPageChange': 'Do you want to leave this page?\nChanges you made may not be saved.',
    'user.profile.companyOrganizationName.explain1':
      'Once entered and saved, the users you’ve given access to will find it listed on the Providers menu on their ',
    'user.profile.companyOrganizationName.explain2': '.',
    'user.profile.successfullySaved': 'Your profile has been saved.',
    'user.profile.copyLink': 'Copy Link',
    'user.profile.linkCopied': 'Link copied.',
    'user.profile.saveAccountFailed': 'Failed to save account information',
    'user.noProvider': 'No providers yet',
    'user.welcome.title': 'Find a page from the list, without looking up the URL.',
    'user.welcome.explain': 'You can quickly find what you’re looking for if you narrow the list down by the provider.',
    'user.noView.heading': 'You don’t have any pages that you’ve been given access to.',
    'user.noView.explain': 'The pages you’ve been given access to would be listed here.',
    'user.noMatchingViewForSearch': 'No pages match your search.',
    'user.confirm.public.title': 'Are you sure you want to add your company/organization name to the Providers menu?',
    'user.confirm.public.explain':
      'If your company/organization name is added to the Providers menu, the users you’ve given access to will be able to narrow down forms/views by the name on their User Page.',
    'user.confirm.private.title':
      'Are you sure you want to remove your company/organization name from the Providers menu?',
    'user.confirm.private.explain':
      'If your company/organization name is removed from the Providers menu, the users you’ve given access to will no longer be able to narrow down forms/views by the name on their User Page.',
    'user.confirm.private.caution':
      'Your forms/views will stay public even if you remove your company/organization name from the Providers menu.',
    'validation.maxImageLimit.title': 'Failed to load image',
    'validation.maxImageLimit.explain': 'Image must be {{ maxImageSize }} or less.',
    'validation.required': 'This field is mandatory',
    'validation.invalidEmail': 'Email address format is incorrect',
    'validation.invalidUrl': 'URL format is incorrect',
    'validation.maxTextLimit': 'Longer than the maximum {{ length }} characters',
  },
};
