import * as amplitude from '@amplitude/analytics-browser';
import { NEXT_PUBLIC_AMPLITUDE_API_KEY, NEXT_PUBLIC_SERVICE_ORIGIN } from '@/config/env-client';
import { webAttributionPlugin } from '@amplitude/plugin-web-attribution-browser';
import { useCallback, useEffect } from 'react';
import { useMainAndSubAccounts } from '@/hooks/account';
import {
  EN_PAGE_VIEW_PROFILE_SETTINGS,
  EN_PAGE_VIEW_APPS,
  EN_PAGE_VIEW_SUB_ACCOUNT_INVITATIONS,
  EN_PAGE_VIEW_SUB_ACCOUNT_SETTINGS,
  EP_EVENT_OCCURRED_AT,
  EP_PAGE_PATH,
  EP_PAGE_TITLE,
  EP_SERVICE_NAME,
  EP_URL,
  EPV_SERVICE_NAME_ACCOUNT,
  UP_NUMBER_OF_SERVICES_IN_USE,
  UP_SERVICE_NAMES_IN_USE,
  EN_PAGE_VIEW_LOGIN,
  UP_FIRST_TIME_USE_SERVICES,
  EN_PAGE_VIEW_INTRODUCTION_FORMBRIDGE,
  EN_PAGE_VIEW_INTRODUCTION_KVIEWER,
  EN_PAGE_VIEW_INTRODUCTION_KMAILER,
  EN_PAGE_VIEW_INTRODUCTION_PRINTCREATOR,
  EN_PAGE_VIEW_INTRODUCTION_DATACOLLECT,
  EN_PAGE_VIEW_INTRODUCTION_KBACKUP,
  EN_PAGE_VIEW_ORDER_FORMBRIDGE,
  EN_PAGE_VIEW_ORDER_KVIEWER,
  EN_PAGE_VIEW_ORDER_KMAILER,
  EN_PAGE_VIEW_ORDER_PRINTCREATOR,
  EN_PAGE_VIEW_ORDER_DATACOLLECT,
  EN_PAGE_VIEW_ORDER_KBACKUP,
  EN_PAGE_VIEW_UPGRADE_FORMBRIDGE,
  EN_PAGE_VIEW_UPGRADE_KVIEWER,
  EN_PAGE_VIEW_UPGRADE_KMAILER,
  EN_PAGE_VIEW_UPGRADE_PRINTCREATOR,
  EN_PAGE_VIEW_UPGRADE_DATACOLLECT,
  EN_PAGE_VIEW_UPGRADE_KBACKUP,
} from '@/config/amplitude';
import { useRouter } from 'next/router';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';

export const useTrackEvent = () => {
  const router = useRouter();
  return useCallback(
    (eventName: string, eventProperties?: Record<string, unknown>) => {
      // asPath は locale が含まれておらず /ja/:path と /:path が区別がつかないため同じとみなす
      const url = new URL(router.locale === 'en' ? `/en${router.asPath}` : router.asPath, NEXT_PUBLIC_SERVICE_ORIGIN);
      amplitude.track(eventName, {
        [EP_URL]: url.toString(),
        [EP_PAGE_PATH]: url.pathname,
        [EP_PAGE_TITLE]: document.title,
        [EP_SERVICE_NAME]: EPV_SERVICE_NAME_ACCOUNT,
        [EP_EVENT_OCCURRED_AT]: dayjs().toISOString(),
        ...eventProperties,
      });
    },
    [router.asPath, router.locale],
  );
};

const getPageViewEventNameFromPath = (path: string) => {
  switch (path) {
    case '/app':
    case '/en/app':
    case '/ja/app':
      return EN_PAGE_VIEW_APPS;
    case '/app/settings/profile':
    case '/en/app/settings/profile':
    case '/ja/app/settings/profile':
      return EN_PAGE_VIEW_PROFILE_SETTINGS;
    case '/app/settings/sub-account':
    case '/en/app/settings/sub-account':
    case '/ja/app/settings/sub-account':
      return EN_PAGE_VIEW_SUB_ACCOUNT_SETTINGS;
    case '/app/settings/sub-account/invitations':
    case '/en/app/settings/sub-account/invitations':
    case '/ja/app/settings/sub-account/invitations':
      return EN_PAGE_VIEW_SUB_ACCOUNT_INVITATIONS;
    case '/login':
    case '/en/login':
    case '/ja/login':
      return EN_PAGE_VIEW_LOGIN;
    case '/app/introduction/form-bridge':
    case '/en/app/introduction/form-bridge':
    case '/ja/app/introduction/form-bridge':
      return EN_PAGE_VIEW_INTRODUCTION_FORMBRIDGE;
    case '/app/introduction/kviewer':
    case '/en/app/introduction/kviewer':
    case '/ja/app/introduction/kviewer':
      return EN_PAGE_VIEW_INTRODUCTION_KVIEWER;
    case '/app/introduction/kmailer':
    case '/en/app/introduction/kmailer':
    case '/ja/app/introduction/kmailer':
      return EN_PAGE_VIEW_INTRODUCTION_KMAILER;
    case '/app/introduction/print-creator':
    case '/en/app/introduction/print-creator':
    case '/ja/app/introduction/print-creator':
      return EN_PAGE_VIEW_INTRODUCTION_PRINTCREATOR;
    case '/app/introduction/data-collect':
    case '/en/app/introduction/data-collect':
    case '/ja/app/introduction/data-collect':
      return EN_PAGE_VIEW_INTRODUCTION_DATACOLLECT;
    case '/app/introduction/kbackup':
    case '/en/app/introduction/kbackup':
    case '/ja/app/introduction/kbackup':
      return EN_PAGE_VIEW_INTRODUCTION_KBACKUP;
    case '/app/order/form-bridge':
    case '/en/app/order/form-bridge':
    case '/ja/app/order/form-bridge':
      return EN_PAGE_VIEW_ORDER_FORMBRIDGE;
    case '/app/order/kviewer':
    case '/en/app/order/kviewer':
    case '/ja/app/order/kviewer':
      return EN_PAGE_VIEW_ORDER_KVIEWER;
    case '/app/order/kmailer':
    case '/en/app/order/kmailer':
    case '/ja/app/order/kmailer':
      return EN_PAGE_VIEW_ORDER_KMAILER;
    case '/app/order/print-creator':
    case '/en/app/order/print-creator':
    case '/ja/app/order/print-creator':
      return EN_PAGE_VIEW_ORDER_PRINTCREATOR;
    case '/app/order/data-collect':
    case '/en/app/order/data-collect':
    case '/ja/app/order/data-collect':
      return EN_PAGE_VIEW_ORDER_DATACOLLECT;
    case '/app/order/kbackup':
    case '/en/app/order/kbackup':
    case '/ja/app/order/kbackup':
      return EN_PAGE_VIEW_ORDER_KBACKUP;
    case '/app/upgrade/form-bridge':
    case '/en/app/upgrade/form-bridge':
    case '/ja/app/upgrade/form-bridge':
      return EN_PAGE_VIEW_UPGRADE_FORMBRIDGE;
    case '/app/upgrade/kviewer':
    case '/en/app/upgrade/kviewer':
    case '/ja/app/upgrade/kviewer':
      return EN_PAGE_VIEW_UPGRADE_KVIEWER;
    case '/app/upgrade/kmailer':
    case '/en/app/upgrade/kmailer':
    case '/ja/app/upgrade/kmailer':
      return EN_PAGE_VIEW_UPGRADE_KMAILER;
    case '/app/upgrade/print-creator':
    case '/en/app/upgrade/print-creator':
    case '/ja/app/upgrade/print-creator':
      return EN_PAGE_VIEW_UPGRADE_PRINTCREATOR;
    case '/app/upgrade/data-collect':
    case '/en/app/upgrade/data-collect':
    case '/ja/app/upgrade/data-collect':
      return EN_PAGE_VIEW_UPGRADE_DATACOLLECT;
    case '/app/upgrade/kbackup':
    case '/en/app/upgrade/kbackup':
    case '/ja/app/upgrade/kbackup':
      return EN_PAGE_VIEW_UPGRADE_KBACKUP;
    default:
      return null;
  }
};

// amplitudeを初期化するためのフック
// 初期表示時の閲覧イベントを初期化後に送信したいので、閲覧系イベントの送信のためのイベントをrouterに登録するのもここで行っている
export const useInitAmplitude = () => {
  const router = useRouter();
  const trackEvent = useTrackEvent();
  useEffect(() => {
    if (!router.isReady) return;
    amplitude.init(NEXT_PUBLIC_AMPLITUDE_API_KEY, {
      defaultTracking: false,
    });
    const webAttribution = webAttributionPlugin();
    amplitude.add(webAttribution);

    const initialPathEvent = getPageViewEventNameFromPath(router.pathname);
    // routeChangeCompleteは画面遷移しないと発火しないので初期描画時に一度送信
    // 送信された数で課金なのでホットリロードしまくる開発環境はOFF
    if (process.env.NODE_ENV !== 'development' && initialPathEvent) {
      trackEvent(initialPathEvent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady]);

  useEffect(() => {
    const handleComplete = (path: string, { shallow }: { shallow: boolean }) => {
      // 今いるパスと同じパスへの遷移は送らない
      if (shallow) {
        return;
      }
      const eventName = getPageViewEventNameFromPath(path);
      if (eventName) {
        trackEvent(eventName);
      }
    };
    router.events.on('routeChangeComplete', handleComplete);
    return () => {
      router.events.off('routeChangeComplete', handleComplete);
    };
  }, [router.events, trackEvent]);
};

export const useSetAccountUP = () => {
  const { fb, kv, km, pc, dc, isAnyPending } = useMainAndSubAccounts();
  useEffect(() => {
    if (isAnyPending) return;
    const identifyEvent = new amplitude.Identify();

    const usingServices = [
      { account: fb.data?.account, serviceName: 'FormBridge' },
      { account: kv.data?.account, serviceName: 'kViewer' },
      { account: km.data?.account, serviceName: 'kMailer' },
      { account: pc.data?.account, serviceName: 'PrintCreator' },
      { account: dc.data?.account, serviceName: 'DataCollect' },
    ].filter((service) => service.account != null);
    const usingServiceNames = usingServices.map((service) => service.serviceName);

    const oldestRegisteredAt = usingServices.reduce<null | Dayjs>((oldestAcc, service) => {
      if (service.account == null) return oldestAcc;
      // kVのみregisteredAtが空文字の場合があるので、その場合は9999-12-31とみなす
      const registeredAt =
        service.account.registeredAt !== '' ? dayjs(service.account?.registeredAt) : dayjs('9999-12-31');
      if (oldestAcc == null) return registeredAt;
      return registeredAt.isBefore(oldestAcc) ? registeredAt : oldestAcc;
    }, null);
    const fistTimeUsingServices = usingServices
      .filter((service) => {
        if (service.account == null) return false;
        const registeredAt =
          service.account.registeredAt !== '' ? dayjs(service.account?.registeredAt) : dayjs('9999-12-31');
        return registeredAt.isSame(oldestRegisteredAt, 'day');
      })
      .map((service) => service.serviceName);

    identifyEvent.set(UP_SERVICE_NAMES_IN_USE, usingServiceNames);
    identifyEvent.set(UP_NUMBER_OF_SERVICES_IN_USE, usingServices.length);
    identifyEvent.set(UP_FIRST_TIME_USE_SERVICES, fistTimeUsingServices);
    amplitude.identify(identifyEvent);
  }, [dc, fb, isAnyPending, km, kv, pc]);
};
