// 選択した環境が期限切れならtrue
import { Plan } from '@/generated/fb-graphql-client';
import dayjs from 'dayjs';

export const isAccountExpired = (account: { plan: Plan; trialExpiresAt?: string | undefined | null }) => {
  if (account.plan !== Plan.Trial || account.trialExpiresAt == null) return false;
  return dayjs(account.trialExpiresAt).isBefore(dayjs().add(1, 'D').startOf('D'));
};

const planToNumber = (plan: Plan) => {
  switch (plan) {
    case Plan.Light:
      return 1;
    case Plan.Standard:
      return 2;
    case Plan.Premium:
      return 3;
    case Plan.Professional:
      return 4;
    case Plan.Enterprise:
      return 5;
    default: {
      return 6;
    }
  }
};

// accountのプランがrequiredPlan以上ならtrue Trialは最高プランとして扱われることに注意
export const isAccountPlanSufficient = (account: { plan: Plan }, requiredPlan: Plan) =>
  planToNumber(account.plan) >= planToNumber(requiredPlan);
