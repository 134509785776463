import type { ActionCodeSettings } from '@firebase/auth-types';
import type { FirebaseOptions } from '@firebase/app';
import { NEXT_PUBLIC_ACTION_CODE_URL, NEXT_PUBLIC_VERCEL_ENV } from '@/config/env-client';

const actionCodeSettings: ActionCodeSettings = {
  handleCodeInApp: true,
  url: NEXT_PUBLIC_ACTION_CODE_URL,
};

// NEXT_PUBLIC_VERCEL_ENV='development' || 'preview' の時は開発用プロジェクトを使う
// ビルドしても両方の設定がファイル上に残るが特に問題ないと思われる
const clientConfig: FirebaseOptions =
  NEXT_PUBLIC_VERCEL_ENV === 'production'
    ? {
        apiKey: 'AIzaSyAs1_pZ7YaP5-uqh3dpY4xxkb3wyv8Aqcs',
        authDomain: 'auth.account.kintoneapp.com',
        projectId: 'account-kintoneapp',
        storageBucket: 'account-kintoneapp.appspot.com',
        messagingSenderId: '275683748412',
        appId: '1:275683748412:web:f5d3d93537c27e729cf139',
        measurementId: 'G-WJ1WELBB2D',
      }
    : {
        apiKey: 'AIzaSyAk-Uje8n5AZ-b0e4tZX_gISvd8UhV39Ys',
        authDomain: 'auth.account.kintoneapp-staging.com',
        projectId: 'kintoneapp-local',
        storageBucket: 'kintoneapp-local.appspot.com',
        messagingSenderId: '38631530910',
        appId: '1:38631530910:web:cd23c6dd69d90fe94db68f',
        measurementId: 'G-XDYSYVTXP8',
      };

export { actionCodeSettings, clientConfig };
