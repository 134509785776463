import React, { useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import { NEXT_PUBLIC_SERVICE_ORIGIN } from '@/config/env-client';
import { restoreBackUrl } from '@/lib/localStorage';
import { isTrustedRedirectHost } from '@/lib/url';
import { AuthContext } from '@/contexts/auth';
import { useUser } from '@/hooks/auth';
import { useIsRedirecting } from '@/hooks/router';
import { SERVICE_ORIGIN } from '@/config/env-server';

type AuthProviderProps = {
  children: React.ReactNode;
};

// backUrlを必要としないパス一覧
const PATH_LIST_NOT_REQUIRING_BACKURL = ['/', '/logout'];

// 認証状態を取得する
// 状態に応じて以下のアクセスコントロールを行う
//
// 認証が済んだ状態で /login にアクセスした時
//   backUrlへ遷移
//   backUrlがなければ / へ遷移
//
// 認証が必要なページに未認証状態でアクセスした時
//   PATH_LIST_NOT_REQUIRING_BACKURL の場合は backUrl なしで /login へ遷移
//   それ以外の場合はアクセスしたページのbackUrlを付与した上で /login に遷移
function AuthProvider({ children }: AuthProviderProps) {
  const { isFetching, isPending, data: user } = useUser();
  const router = useRouter();
  const isAnyonePath = useMemo(() => router.pathname === '/email-verified', [router.pathname]);
  const isGoToLogin = useMemo(
    () => user == null && router.pathname !== '/login' && !isAnyonePath,
    [isAnyonePath, router.pathname, user],
  );
  const isGoToExceptLogin = useMemo(() => user != null && router.pathname === '/login', [router.pathname, user]);

  // リダイレクト中にアクセスコントロールが発生しないようにする必要がある
  const isRedirecting = useIsRedirecting();

  useEffect(() => {
    // asPathはrouter.isReadyじゃないと使えないためチェックする
    if (isFetching || isRedirecting || !router.isReady) return;

    // 認証が必要なページに未認証状態でアクセスしたとき /login に移動
    if (isGoToLogin) {
      const { backUrl } = router.query;
      // /logout に backUrl付きでアクセスしていた場合はそれをそのまま /login へ渡す
      if (router.pathname === '/logout' && backUrl != null && typeof backUrl === 'string') {
        router.push(`/login?backUrl=${encodeURIComponent(backUrl)}`);
        return;
      }

      // PATH_LIST_NOT_REQUIRING_BACKURL に含まれる場合はbackUrlなしで login へ遷移
      if (PATH_LIST_NOT_REQUIRING_BACKURL.includes(router.pathname)) {
        router.push('/login');
        return;
      }

      // PATH_LIST_NOT_REQUIRING_BACKURL 以外のパスでは backUrlを付与した上で login に遷移
      // ※backUrlはisTrustedRedirectHostで検証されるのでHostの部分も含める必要がある
      router.push(`/login?backUrl=${encodeURIComponent(`${NEXT_PUBLIC_SERVICE_ORIGIN}${router.asPath}`)}`);
      return;
    }

    // 認証されていてログイン画面にいる場合
    if (isGoToExceptLogin) {
      // backUrlがついている場合はbackUrlに遷移
      const backUrl = restoreBackUrl();
      if (backUrl && isTrustedRedirectHost(backUrl)) {
        const bUrl = new URL(backUrl);
        // 外部サイトに移動する場合はrouteChangeStartが発火されないので、リダイレクト中にuseEffectが実行されないように手動でemitする
        if (bUrl.origin !== SERVICE_ORIGIN) {
          router.events.emit('routeChangeStart');
        }
        router.push(backUrl);
        return;
      }
      // backUrlがついていない場合はユーザーページに遷移
      router.replace('/');
    }
  }, [isGoToExceptLogin, isGoToLogin, router, isRedirecting, isFetching]);

  // 認証しているかどうか判定中は何も出さない
  if (isPending) return null;

  return <AuthContext.Provider value={user}>{children}</AuthContext.Provider>;
}

export default AuthProvider;
