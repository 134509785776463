import { useG11n } from 'next-g11n';
import { dictionary } from '@/lib/dictionary';
import { useRouter } from 'next/router';
import type { ReactNode } from 'react';

// 翻訳関数を返す
export const useTranslation = () => {
  // next-g11nが@types/react18に対応していなかったため、型アサーションを行っている
  const { translate } = useG11n<typeof dictionary>(dictionary) as {
    translate: (
      term: keyof (typeof dictionary)[keyof typeof dictionary],
      params?: Record<string, string | number | bigint | null | undefined>,
    ) => ReactNode;
  };
  return { t: translate };
};

// 日本語表記ならtrue
export const useIsJa = () => {
  const { locale } = useRouter();
  return locale === 'ja';
};
