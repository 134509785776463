import { GraphQLClient, RequestOptions } from 'graphql-request';
import gql from 'graphql-tag';
import { graphql, type GraphQLResponseResolver, type RequestHandlerOptions } from 'msw';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
type GraphQLClientRequestHeaders = RequestOptions['requestHeaders'];
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
};

/** アカウント */
export type Account = AccountInterface & {
  __typename?: 'Account';
  agreements?: Maybe<Array<UserAgreement>>;
  company: Scalars['String']['output'];
  /** 小文字化されたメールアドレス */
  email: Scalars['String']['output'];
  isVerifyingEmail: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  permissions?: Maybe<Array<Permission>>;
  plan: Plan;
  /** DB内の生のid。adminとの連携など生のidを参照したいときに使用する。refetch用には使えない。 */
  rawId: Scalars['String']['output'];
  /** ユーザーの登録日時を示すISO8601文字列。製品によってはcreatedAtだが、registeredAtに統一する。 */
  registeredAt: Scalars['String']['output'];
  subAccount?: Maybe<SubAccount>;
  /** planがお試し中の時に入るお試し終了期日を示すISO8601文字列 */
  trialExpiresAt?: Maybe<Scalars['String']['output']>;
};

/**
 * アカウントの共通型。
 * Toyokukumo kintoneAppアカウントでは複数製品のAccountを扱うため共通の型を定義する。
 */
export type AccountInterface = {
  /** 製品によってはDBのスキーマ上だとnullableなので、nullを返さないように注意する */
  company: Scalars['String']['output'];
  /** 小文字化されたメールアドレス */
  email: Scalars['String']['output'];
  name: Scalars['String']['output'];
  plan: Plan;
  /** DB内の生のid。adminとの連携など生のidを参照したいときに使用する。refetch用には使えない。 */
  rawId: Scalars['String']['output'];
  /** ユーザーの登録日時を示すISO8601文字列。製品によってはcreatedAtだが、registeredAtに統一する。 */
  registeredAt: Scalars['String']['output'];
  /** planがお試し中の時に入るお試し終了期日を示すISO8601文字列 */
  trialExpiresAt?: Maybe<Scalars['String']['output']>;
};

/** サブアカウントを追加するリクエストをした時の戻り値 */
export type AddSubAccountPayload = SubAccount | UserError;

/** 同意結果 */
export type AgreeResult = {
  __typename?: 'AgreeResult';
  success: Scalars['Boolean']['output'];
};

/** kmailer.query.user-agreement/AwytlgreementTarget */
export enum AgreementTarget {
  AiAssistant = 'AI_ASSISTANT',
}

export enum CompletionEvento {
  Done = 'DONE',
  Generating = 'GENERATING',
}

/** kmailer.query.configured/Configured */
export type Configured = {
  __typename?: 'Configured';
  id: Scalars['ID']['output'];
};

export type CreateExternalTemplateInput = {
  body: Scalars['String']['input'];
  externalId: Scalars['String']['input'];
  service: ExternalService;
  subject: Scalars['String']['input'];
};

/** FB/kV連携などにおけるテンプレートの作成の戻り値 */
export type CreateExternalTemplatePayload = CreateExternalTemplateResult | UserError;

/** FB/kV連携などにおけるテンプレート作成の結果 */
export type CreateExternalTemplateResult = {
  __typename?: 'CreateExternalTemplateResult';
  success: Scalars['Boolean']['output'];
};

/** FB/kV連携などにおけるテンプレートの削除の戻り値 */
export type DeleteExternalTemplatePayload = DeleteExternalTemplateResult | UserError;

/** FB/kV連携などにおけるテンプレート削除の結果 */
export type DeleteExternalTemplateResult = {
  __typename?: 'DeleteExternalTemplateResult';
  success: Scalars['Boolean']['output'];
};

/** FB/kV連携などにおけるメール送信の戻り値 */
export type DeleteMailsetPayload = DeleteMailsetResult | UserError;

/** メールセット削除の結果 */
export type DeleteMailsetResult = {
  __typename?: 'DeleteMailsetResult';
  success: Scalars['Boolean']['output'];
};

/** エラーの詳細。どの箇所でどんなエラーが出ているか。 */
export type ErrorField = {
  __typename?: 'ErrorField';
  message: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

/** FB/kV連携などにおける外部サービスの宛先 */
export type ExternalAddressInput = {
  address: Scalars['String']['input'];
  attributes?: InputMaybe<Array<InputMaybe<ExternalAttributeInput>>>;
};

/** FB/kV連携などにおける外部サービスの属性値 */
export type ExternalAttributeInput = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export enum ExternalService {
  Formbridge = 'FORMBRIDGE',
  Kviewer = 'KVIEWER',
}

/** FB/kV連携向けのテンプレート */
export type ExternalTemplate = {
  __typename?: 'ExternalTemplate';
  body: Scalars['String']['output'];
  externalId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  modifiedAt: Scalars['String']['output'];
  service: ExternalService;
  subject: Scalars['String']['output'];
};

/** 指示をもとにOpenAI APIで生成されたメールテンプレート取得時の戻り値 */
export type FetchCompletionPayload = FetchCompletionResult | UserError;

/** OpenAI APIで生成されたメールテンプレート */
export type FetchCompletionResult = {
  __typename?: 'FetchCompletionResult';
  event: CompletionEvento;
  response: Scalars['String']['output'];
};

export enum HistoryStore {
  Kintone = 'KINTONE',
  Kmailer = 'KMAILER',
}

/** kM内部に保存されているアクションログ */
export type InnerLog = {
  __typename?: 'InnerLog';
  addressId: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  ipAddress?: Maybe<Scalars['String']['output']>;
  mailAddress?: Maybe<Scalars['String']['output']>;
  mailId: Scalars['String']['output'];
  message: Scalars['String']['output'];
  type: Scalars['String']['output'];
  uniqueId: Scalars['String']['output'];
};

/** kM内部の送信履歴一覧を取得した時の戻り値 */
export type InnerSentHistoriesPayload = {
  __typename?: 'InnerSentHistoriesPayload';
  results?: Maybe<Array<Maybe<InnerSentHistory>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** kM内部に保存されている送信履歴 */
export type InnerSentHistory = {
  __typename?: 'InnerSentHistory';
  addressId: Scalars['String']['output'];
  bccAddress?: Maybe<Scalars['String']['output']>;
  body: Scalars['String']['output'];
  ccAddress?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  fromAddress: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  innerLogs?: Maybe<Array<InnerLog>>;
  mailId: Scalars['String']['output'];
  operator: Scalars['String']['output'];
  result: Scalars['String']['output'];
  subject: Scalars['String']['output'];
  toAddress?: Maybe<Scalars['String']['output']>;
  uniqueId: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

/** 招待中のステータス定義 */
export enum InvitationStatus {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
}

/** kmailer.query.kintone-app/KintoneApp */
export type KintoneApp = {
  __typename?: 'KintoneApp';
  appId: Scalars['Int']['output'];
  guestSpaceId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  info?: Maybe<KintoneAppInfo>;
  token: Scalars['String']['output'];
};

export type KintoneAppInfo = {
  __typename?: 'KintoneAppInfo';
  name: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

/** kmailer.query.kintone-basic-auth-setting/KintoneBasicAuthSetting */
export type KintoneBasicAuthSetting = {
  __typename?: 'KintoneBasicAuthSetting';
  id: Scalars['ID']['output'];
  password: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

/** kmailer.query.kintone-setting/KintoneSetting */
export type KintoneSetting = {
  __typename?: 'KintoneSetting';
  historyStore: HistoryStore;
  id: Scalars['ID']['output'];
  url: Scalars['String']['output'];
};

/** kintone情報を取得した時の戻り値 */
export type KintoneSettingPayload = {
  __typename?: 'KintoneSettingPayload';
  basicAuth?: Maybe<KintoneBasicAuthSetting>;
  logApp?: Maybe<KintoneApp>;
  sentHistoryApp?: Maybe<KintoneApp>;
  setting: KintoneSetting;
};

/** FB/kV連携における直近の送信/予約状況 */
export type LatestExternalSending = {
  __typename?: 'LatestExternalSending';
  externalId: Scalars['String']['output'];
  isDatetimeSpecified: Scalars['Boolean']['output'];
  mailCount: Scalars['Int']['output'];
  requestedAt: Scalars['String']['output'];
  sentMailId: Scalars['String']['output'];
  service: ExternalService;
  subject: Scalars['String']['output'];
  userId: Scalars['String']['output'];
  waitingMailCount?: Maybe<Scalars['Int']['output']>;
};

/** kmailer.query.mailset/MailsetWithAttr */
export type Mailset = {
  __typename?: 'Mailset';
  addressApp?: Maybe<KintoneApp>;
  bccField?: Maybe<Scalars['String']['output']>;
  ccField?: Maybe<Scalars['String']['output']>;
  emailField: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  priority: Scalars['Int']['output'];
  templateApp?: Maybe<KintoneApp>;
  uuid: Scalars['String']['output'];
};

/** アカウントのうち、サブアカウントから参照されるときのアカウント定義 */
export type MainAccount = AccountInterface & {
  __typename?: 'MainAccount';
  company: Scalars['String']['output'];
  email: Scalars['String']['output'];
  name: Scalars['String']['output'];
  plan: Plan;
  /** DB内の生のid。adminとの連携など生のidを参照したいときに使用する。refetch用には使えない。 */
  rawId: Scalars['String']['output'];
  /** ユーザーの登録日時を示すISO8601文字列。製品によってはcreatedAtだが、registeredAtに統一する。 */
  registeredAt: Scalars['String']['output'];
  /** planがお試し中の時に入るお試し終了期日を示すISO8601文字列 */
  trialExpiresAt?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /**
   * 自分自身のアドレスで持っている環境にサブアカウントを追加する。
   * 自分自身のアドレスと同じメールアドレスでは追加できない。
   * 追加したサブアカウントは PENDING として追加され、account.kintoneapp.comで承諾することで認可される。
   * メインアカウントは関係がないことに注意
   * role :has-main-account
   */
  addSubAccount: AddSubAccountPayload;
  /**
   * AIアシスタントβの利用規約に同意
   *
   * role :account
   */
  agreeToAiAssistant: AgreeResult;
  /**
   * 自分自身のアドレスがサブアカウントである環境に対して、招待の承諾を行う
   * サブアカウントのステータスをPENDINGからAPPROVEDに変更する。
   *
   * role :authenticated
   * mainAccountEmail: 自分自身を招待しているメインアカウントのemail
   */
  approveSubAccount: UpdateSubAccountPayload;
  /**
   * FB/kV連携などにおけるテンプレートの作成処理
   *
   * role :account
   */
  createExternalTemplate: CreateExternalTemplatePayload;
  /**
   * FB/kV連携などにおけるテンプレートの削除処理
   *
   * role :account
   */
  deleteExternalTemplate: DeleteExternalTemplatePayload;
  /**
   * メールセットの削除処理
   *
   * role :account
   */
  deleteMailset: DeleteMailsetPayload;
  /**
   * 自分自身のアドレスで持っている環境のサブアカウントを削除する。
   * 削除対象が存在しない場合にUserErrorが返る
   * メインアカウントは関係がないことに注意
   * role :has-main-account
   */
  deleteSubAccount?: Maybe<UserError>;
  /**
   * 自分自身のアドレスがサブアカウントである環境に対して、招待の辞退を行う
   * サブアカウントのステータスをPENDINGからREJECTEDに変更する。
   *
   * role :authenticated
   * mainAccountEmail: 自分自身を招待しているメインアカウントのemail
   */
  rejectSubAccount: UpdateSubAccountPayload;
  /**
   * FB/kV連携などにおけるメール送信
   *
   * role :account
   */
  sendExternalMail: SendExternalMailPayload;
  /**
   * 自分自身の所有している環境のアカウントの所有者をサブ管理者に変更する
   * 元の自分自身はサブ管理者になる
   *
   * role :has-main-account
   */
  swapOwnerWithSubAccount?: Maybe<UserError>;
  /**
   * FB/kV連携などにおけるテンプレートの更新処理
   *
   * role :account
   */
  updateExternalTemplate: UpdateExternalTemplatePayload;
  /**
   * 自分自身の所有している環境のアカウント情報を変更する
   *
   * role :has-main-account
   * mainAccountEmail: 自分自身を招待しているメインアカウントのemail
   */
  updateOwnAccount: UpdateOwnAccountPayload;
};

export type MutationAddSubAccountArgs = {
  email: Scalars['String']['input'];
};

export type MutationApproveSubAccountArgs = {
  mainAccountEmail: Scalars['String']['input'];
};

export type MutationCreateExternalTemplateArgs = {
  input: CreateExternalTemplateInput;
};

export type MutationDeleteExternalTemplateArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteMailsetArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRejectSubAccountArgs = {
  mainAccountEmail: Scalars['String']['input'];
};

export type MutationSendExternalMailArgs = {
  input: SendExternalMailInput;
};

export type MutationUpdateExternalTemplateArgs = {
  input: UpdateExternalTemplateInput;
};

export type MutationUpdateOwnAccountArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** kmailer.query.permission/Permission */
export type Permission = {
  __typename?: 'Permission';
  code: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

/** 全kintone連携製品共通の型定義 */
export enum Plan {
  Light = 'LIGHT',
  Premium = 'PREMIUM',
  Professional = 'PROFESSIONAL',
  Standard = 'STANDARD',
  Trial = 'TRIAL',
}

export type Query = {
  __typename?: 'Query';
  /**
   * 自分自身のアドレスに紐づくアカウント情報を取得する
   * アカウントの選択は関係ないことに注意が必要
   * メインアカウントは関係がないことに注意
   * role :authenticated
   */
  account?: Maybe<Account>;
  /** FB/kV連携における直近の送信/予約状況をすべて取得する */
  allLatestExternalSendings: Array<LatestExternalSending>;
  /** 初期設定状況を取得する */
  configured?: Maybe<Configured>;
  /** FB/kV連携向けのテンプレートを取得 */
  externalTemplate?: Maybe<ExternalTemplate>;
  /** FB/kV連携向けのテンプレートの一覧を取得 */
  externalTemplates: Array<ExternalTemplate>;
  /** 開始されたメールテンプレート生成の結果を取得する */
  fetchCompletionResult: FetchCompletionPayload;
  /** kM内部に保存されているアクションログを取得する */
  innerLog?: Maybe<InnerLog>;
  /** kM内部に保存されている送信履歴のリストを取得する */
  innerSentHistories: InnerSentHistoriesPayload;
  /** kM内部に保存されている送信履歴を取得する */
  innerSentHistory?: Maybe<InnerSentHistory>;
  /** kintone情報を取得する */
  kintoneSetting?: Maybe<KintoneSettingPayload>;
  /** FB/kV連携における直近の送信/予約状況を取得する */
  latestExternalSending?: Maybe<LatestExternalSending>;
  /** メールセットの一覧を取得 */
  mailsets: Array<Maybe<Mailset>>;
  /**
   * アカウント選択で選択したアカウント情報を取得する
   * role :account
   */
  mainAccount: Account;
  /**
   * 自分自身のアドレスがサブアカウントであるリストを取得する
   * サブアカウントを登録したメインアカウントのプランは無料お試しかつ無料お試し期間内または契約済
   * 招待未承諾であるものだけ
   * メインアカウントは関係がないことに注意
   * role :authenticated
   */
  pendingSubAccounts: Array<SubAccount>;
  /**
   * 指示をもとにOpenAI APIでのメールテンプレート生成を開始する
   * 実際に生成されたメールテンプレートはfetchCompletionResultで取得する
   */
  startToCompleteHtmlMail: StartToCompleteHtmlMailPayload;
  /**
   * 指示をもとに生成されたテンプレートの修正を開始する
   * 実際に生成されたメールテンプレートはfetchCompletionResultで取得する
   */
  startToReviseHtmlMail: StartToCompleteHtmlMailPayload;
  /** 開始されたメールテンプレート生成を停止する */
  stopCompletion: StopCompletionResult;
  /**
   * 自分自身のアドレスがサブアカウントであるリストを取得する
   * サブアカウントを登録したメインアカウントのプランは無料お試しかつ無料お試し期間内または契約済
   * 招待承諾済であるものだけ
   * メインアカウントは関係がないことに注意
   * role :authenticated
   */
  subAccounts: Array<SubAccount>;
};

export type QueryExternalTemplateArgs = {
  id: Scalars['ID']['input'];
};

export type QueryExternalTemplatesArgs = {
  externalId?: InputMaybe<Scalars['String']['input']>;
  service?: InputMaybe<ExternalService>;
};

export type QueryFetchCompletionResultArgs = {
  id: Scalars['ID']['input'];
};

export type QueryInnerLogArgs = {
  id: Scalars['ID']['input'];
};

export type QueryInnerSentHistoriesArgs = {
  direction?: InputMaybe<Scalars['String']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
};

export type QueryInnerSentHistoryArgs = {
  id: Scalars['ID']['input'];
};

export type QueryLatestExternalSendingArgs = {
  externalId: Scalars['String']['input'];
  service: ExternalService;
};

export type QueryStartToCompleteHtmlMailArgs = {
  prompt: Scalars['String']['input'];
};

export type QueryStartToReviseHtmlMailArgs = {
  previousHtml: Scalars['String']['input'];
  prompt: Scalars['String']['input'];
};

export type QueryStopCompletionArgs = {
  id: Scalars['ID']['input'];
};

/** FB/kV連携などにおけるメール送信時の入力値 */
export type SendExternalMailInput = {
  addresses: Array<ExternalAddressInput>;
  body: Scalars['String']['input'];
  externalId: Scalars['String']['input'];
  reservedAt?: InputMaybe<Scalars['String']['input']>;
  saveAsTemplate?: InputMaybe<Scalars['Boolean']['input']>;
  service: ExternalService;
  subject: Scalars['String']['input'];
};

/** FB/kV連携などにおけるメール送信の戻り値 */
export type SendExternalMailPayload = SendExternalMailResult | UserError;

/** FB/kV連携などにおけるメール送信の結果 */
export type SendExternalMailResult = {
  __typename?: 'SendExternalMailResult';
  success: Scalars['Boolean']['output'];
};

/** メールテンプレート生成の開始時の戻り値 */
export type StartToCompleteHtmlMailPayload = StartToCompletionResult | UserError;

/** メールテンプレートの生成を開始した結果 */
export type StartToCompletionResult = {
  __typename?: 'StartToCompletionResult';
  id: Scalars['ID']['output'];
};

/** メールテンプレートの生成を停止した結果 */
export type StopCompletionResult = {
  __typename?: 'StopCompletionResult';
  success: Scalars['Boolean']['output'];
};

/**
 * サブアカウント
 * アカウントは1つだけサブアカウントを設定できる
 */
export type SubAccount = {
  __typename?: 'SubAccount';
  email: Scalars['String']['output'];
  mainAccount: MainAccount;
  status: InvitationStatus;
};

export type UpdateExternalTemplateInput = {
  body: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  subject: Scalars['String']['input'];
};

/** FB/kV連携などにおけるテンプレートの更新の戻り値 */
export type UpdateExternalTemplatePayload = UpdateExternalTemplateResult | UserError;

/** FB/kV連携などにおけるテンプレート編集の結果 */
export type UpdateExternalTemplateResult = {
  __typename?: 'UpdateExternalTemplateResult';
  success: Scalars['Boolean']['output'];
};

/** 自分自身が所有するアカウントを更新した時の戻り値 */
export type UpdateOwnAccountPayload = MainAccount | UserError;

/** 招待されているサブアカウントの承諾または辞退をした時の戻り値 */
export type UpdateSubAccountPayload = SubAccount | UserError;

/** kmailer.query.user-agreement/UserAgreement */
export type UserAgreement = {
  __typename?: 'UserAgreement';
  createdAt: Scalars['String']['output'];
  target: AgreementTarget;
  userId: Scalars['String']['output'];
};

/**
 * ユーザーの入力によって生じたエラーを示す
 * 原則としてユーザーの入力を修正することでリクエストは成功する可能性がある
 * バリデーションエラーなどドメインロジックに関係するエラー、復帰可能なエラー、などを伴う処理はこれの union を返す
 * mutaion では基本的に使うべき
 * query では引数の検証などが必要な時は使うべき。引数が無い場合は使わないこともある
 */
export type UserError = {
  __typename?: 'UserError';
  /**
   * エラーの種別。主にプログラムで処理を分ける用
   * TODO: 決まってきたら Enum にする
   */
  code: Scalars['String']['output'];
  /**
   * エラーの詳細の一覧。
   * 例： [{ name: "email", message: "emailの仕様に合ってません" }, { name: token, message: "期限切れです" }]
   */
  fields: Array<ErrorField>;
  /**
   * ユーザーに見せるためのエラーメッセージ
   * 例: 入力されたEmailが正しくありません。
   */
  message: Scalars['String']['output'];
};

export type AddSubAccountMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;

export type AddSubAccountMutation = {
  __typename?: 'Mutation';
  addSubAccount:
    | { __typename: 'SubAccount'; email: string }
    | {
        __typename: 'UserError';
        code: string;
        message: string;
        fields: Array<{ __typename?: 'ErrorField'; name: string; message: string }>;
      };
};

export type ApproveSubAccountMutationVariables = Exact<{
  mainAccountEmail: Scalars['String']['input'];
}>;

export type ApproveSubAccountMutation = {
  __typename?: 'Mutation';
  approveSubAccount: { __typename: 'SubAccount'; email: string } | { __typename: 'UserError'; message: string };
};

export type DeleteSubAccountMutationVariables = Exact<{ [key: string]: never }>;

export type DeleteSubAccountMutation = {
  __typename?: 'Mutation';
  deleteSubAccount?: { __typename: 'UserError'; code: string; message: string } | null;
};

export type MainAndSubAccountsQueryVariables = Exact<{ [key: string]: never }>;

export type MainAndSubAccountsQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    rawId: string;
    name: string;
    email: string;
    plan: Plan;
    company: string;
    trialExpiresAt?: string | null;
    registeredAt: string;
    subAccount?: { __typename?: 'SubAccount'; email: string; status: InvitationStatus } | null;
  } | null;
  subAccounts: Array<{
    __typename?: 'SubAccount';
    email: string;
    mainAccount: { __typename?: 'MainAccount'; email: string; plan: Plan; trialExpiresAt?: string | null };
  }>;
};

export type PendingSubAccountsQueryVariables = Exact<{ [key: string]: never }>;

export type PendingSubAccountsQuery = {
  __typename?: 'Query';
  pendingSubAccounts: Array<{
    __typename?: 'SubAccount';
    email: string;
    status: InvitationStatus;
    mainAccount: { __typename?: 'MainAccount'; email: string; plan: Plan; trialExpiresAt?: string | null };
  }>;
};

export type RejectSubAccountMutationVariables = Exact<{
  mainAccountEmail: Scalars['String']['input'];
}>;

export type RejectSubAccountMutation = {
  __typename?: 'Mutation';
  rejectSubAccount: { __typename: 'SubAccount'; email: string } | { __typename: 'UserError'; message: string };
};

export type SwapOwnerWithSubAccountMutationVariables = Exact<{ [key: string]: never }>;

export type SwapOwnerWithSubAccountMutation = {
  __typename?: 'Mutation';
  swapOwnerWithSubAccount?: {
    __typename: 'UserError';
    code: string;
    message: string;
    fields: Array<{ __typename?: 'ErrorField'; name: string; message: string }>;
  } | null;
};

export const AddSubAccountDocument = gql`
  mutation addSubAccount($email: String!) {
    addSubAccount(email: $email) {
      __typename
      ... on SubAccount {
        email
      }
      ... on UserError {
        code
        message
        fields {
          name
          message
        }
      }
    }
  }
`;
export const ApproveSubAccountDocument = gql`
  mutation approveSubAccount($mainAccountEmail: String!) {
    approveSubAccount(mainAccountEmail: $mainAccountEmail) {
      __typename
      ... on SubAccount {
        email
      }
      ... on UserError {
        message
      }
    }
  }
`;
export const DeleteSubAccountDocument = gql`
  mutation deleteSubAccount {
    deleteSubAccount {
      __typename
      code
      message
    }
  }
`;
export const MainAndSubAccountsDocument = gql`
  query mainAndSubAccounts {
    account {
      rawId
      name
      email
      plan
      company
      trialExpiresAt
      registeredAt
      subAccount {
        email
        status
      }
    }
    subAccounts {
      email
      mainAccount {
        email
        plan
        trialExpiresAt
      }
    }
  }
`;
export const PendingSubAccountsDocument = gql`
  query pendingSubAccounts {
    pendingSubAccounts {
      email
      status
      mainAccount {
        email
        plan
        trialExpiresAt
      }
    }
  }
`;
export const RejectSubAccountDocument = gql`
  mutation rejectSubAccount($mainAccountEmail: String!) {
    rejectSubAccount(mainAccountEmail: $mainAccountEmail) {
      __typename
      ... on SubAccount {
        email
      }
      ... on UserError {
        message
      }
    }
  }
`;
export const SwapOwnerWithSubAccountDocument = gql`
  mutation swapOwnerWithSubAccount {
    swapOwnerWithSubAccount {
      __typename
      code
      message
      fields {
        name
        message
      }
    }
  }
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string,
  variables?: any,
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType, _variables) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    addSubAccount(
      variables: AddSubAccountMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<AddSubAccountMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<AddSubAccountMutation>(AddSubAccountDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'addSubAccount',
        'mutation',
        variables,
      );
    },
    approveSubAccount(
      variables: ApproveSubAccountMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ApproveSubAccountMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ApproveSubAccountMutation>(ApproveSubAccountDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'approveSubAccount',
        'mutation',
        variables,
      );
    },
    deleteSubAccount(
      variables?: DeleteSubAccountMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<DeleteSubAccountMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<DeleteSubAccountMutation>(DeleteSubAccountDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'deleteSubAccount',
        'mutation',
        variables,
      );
    },
    mainAndSubAccounts(
      variables?: MainAndSubAccountsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<MainAndSubAccountsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<MainAndSubAccountsQuery>(MainAndSubAccountsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'mainAndSubAccounts',
        'query',
        variables,
      );
    },
    pendingSubAccounts(
      variables?: PendingSubAccountsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<PendingSubAccountsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<PendingSubAccountsQuery>(PendingSubAccountsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'pendingSubAccounts',
        'query',
        variables,
      );
    },
    rejectSubAccount(
      variables: RejectSubAccountMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<RejectSubAccountMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<RejectSubAccountMutation>(RejectSubAccountDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'rejectSubAccount',
        'mutation',
        variables,
      );
    },
    swapOwnerWithSubAccount(
      variables?: SwapOwnerWithSubAccountMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<SwapOwnerWithSubAccountMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<SwapOwnerWithSubAccountMutation>(SwapOwnerWithSubAccountDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'swapOwnerWithSubAccount',
        'mutation',
        variables,
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockAddSubAccountMutation(
 *   ({ query, variables }) => {
 *     const { email } = variables;
 *     return HttpResponse.json({
 *       data: { addSubAccount }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockAddSubAccountMutation = (
  resolver: GraphQLResponseResolver<AddSubAccountMutation, AddSubAccountMutationVariables>,
  options?: RequestHandlerOptions,
) => graphql.mutation<AddSubAccountMutation, AddSubAccountMutationVariables>('addSubAccount', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockApproveSubAccountMutation(
 *   ({ query, variables }) => {
 *     const { mainAccountEmail } = variables;
 *     return HttpResponse.json({
 *       data: { approveSubAccount }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockApproveSubAccountMutation = (
  resolver: GraphQLResponseResolver<ApproveSubAccountMutation, ApproveSubAccountMutationVariables>,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<ApproveSubAccountMutation, ApproveSubAccountMutationVariables>(
    'approveSubAccount',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockDeleteSubAccountMutation(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { deleteSubAccount }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockDeleteSubAccountMutation = (
  resolver: GraphQLResponseResolver<DeleteSubAccountMutation, DeleteSubAccountMutationVariables>,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<DeleteSubAccountMutation, DeleteSubAccountMutationVariables>('deleteSubAccount', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockMainAndSubAccountsQuery(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { account, subAccounts }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockMainAndSubAccountsQuery = (
  resolver: GraphQLResponseResolver<MainAndSubAccountsQuery, MainAndSubAccountsQueryVariables>,
  options?: RequestHandlerOptions,
) => graphql.query<MainAndSubAccountsQuery, MainAndSubAccountsQueryVariables>('mainAndSubAccounts', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockPendingSubAccountsQuery(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { pendingSubAccounts }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockPendingSubAccountsQuery = (
  resolver: GraphQLResponseResolver<PendingSubAccountsQuery, PendingSubAccountsQueryVariables>,
  options?: RequestHandlerOptions,
) => graphql.query<PendingSubAccountsQuery, PendingSubAccountsQueryVariables>('pendingSubAccounts', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockRejectSubAccountMutation(
 *   ({ query, variables }) => {
 *     const { mainAccountEmail } = variables;
 *     return HttpResponse.json({
 *       data: { rejectSubAccount }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockRejectSubAccountMutation = (
  resolver: GraphQLResponseResolver<RejectSubAccountMutation, RejectSubAccountMutationVariables>,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<RejectSubAccountMutation, RejectSubAccountMutationVariables>('rejectSubAccount', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockSwapOwnerWithSubAccountMutation(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { swapOwnerWithSubAccount }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockSwapOwnerWithSubAccountMutation = (
  resolver: GraphQLResponseResolver<SwapOwnerWithSubAccountMutation, SwapOwnerWithSubAccountMutationVariables>,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<SwapOwnerWithSubAccountMutation, SwapOwnerWithSubAccountMutationVariables>(
    'swapOwnerWithSubAccount',
    resolver,
    options,
  );
