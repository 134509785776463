import { getApp as _getApp, getApps, initializeApp } from 'firebase/app';
import type { User } from 'firebase/auth';
import {
  AuthErrorCodes,
  getAuth as _getAuth,
  GoogleAuthProvider,
  isSignInWithEmailLink as _isSignInWithEmailLink,
  OAuthProvider,
  onAuthStateChanged as _onAuthStateChanged,
  sendEmailVerification as _sendEmailVerification,
  SignInMethod as _SignInMethod,
  signInWithEmailLink as _signInWithEmailLink,
  fetchSignInMethodsForEmail as _fetchSignInMethodsForEmail,
  signInWithPopup,
  signOut as _signOut,
} from 'firebase/auth';
import { clientConfig } from '@/config/firebase-client';
import { fetcher } from '@/lib/api-client';
import type { SendSignInEmailRequest } from '@/pages/api/send-sign-in-email';
import type { useTranslation } from '@/hooks/i18n';

export const MICROSOFT_PROVIDER_ID = 'microsoft.com';

// microsoft だけなぜか無いので拡張. 実際の fetchSignInMethodsForEmail で返る methods の値を使った.
const SignInMethod = {
  ..._SignInMethod,
  MICROSOFT: MICROSOFT_PROVIDER_ID,
};

// getAuth
const getApp = () => {
  if (getApps().length > 0) {
    return _getApp();
  }
  return initializeApp(clientConfig);
};

// auth の扱いが特殊で意識させたくないので、この関数はexport禁止
const getAuth = () => {
  const app = getApp();
  const auth = _getAuth(app);
  auth.useDeviceLanguage();
  return auth;
};

// get CurrentUser
const getCurrentUser = () => {
  const auth = getAuth();
  return auth.currentUser;
};

// get User from auth state

const onAuthStateChanged = (
  nextOrObserver: Parameters<typeof _onAuthStateChanged>[1],
  error?: Parameters<typeof _onAuthStateChanged>[2],
  completed?: Parameters<typeof _onAuthStateChanged>[3],
) => {
  const auth = getAuth();
  return _onAuthStateChanged(auth, nextOrObserver, error, completed);
};

// Email Login

// firebase経由で送信すると Content-Type:text/plain でなぜかサインインリンクが含まれないので自前でメール送信している.
const sendSignInLinkToEmail = async (params: SendSignInEmailRequest) => {
  await fetcher.post('/api/send-sign-in-email', params);
};
const isSignInWithEmailLink = (emailLink: string) => {
  const auth = getAuth();
  return _isSignInWithEmailLink(auth, emailLink);
};
const signInWithEmailLink = async (email: string, emailLink: string, { t }: ReturnType<typeof useTranslation>) => {
  const auth = getAuth();
  try {
    return await _signInWithEmailLink(auth, email, emailLink);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    switch (error?.code) {
      case AuthErrorCodes.INVALID_OOB_CODE:
        alert(`${t('login.usedLoginLink')}${t('login.again')}`);
        break;
      case AuthErrorCodes.EXPIRED_OOB_CODE:
        alert(`${t('login.loginLinkExpired')}${t('login.again')}`);
        break;
      case AuthErrorCodes.INVALID_EMAIL:
        alert(`${t('login.invalidEmail')}${t('login.again')}`);
        break;
      default:
    }
    throw error;
  }
};

// Google Login

const signInWithGoogle = () => {
  const auth = getAuth();
  return signInWithPopup(auth, new GoogleAuthProvider());
};

// MS Login

const signInWithMicrosoft = () => {
  const auth = getAuth();
  return signInWithPopup(auth, new OAuthProvider(SignInMethod.MICROSOFT));
};

const sendEmailVerification = async (user: User, completed: () => void) => {
  // onAuthStateChanged は email_verified の変更を検知できないので仕方なく user.reload する
  const id = setInterval(async () => {
    await user.reload();
    if (user.emailVerified) {
      clearInterval(id);
      // 現在の idToken は email_verified:false なので更新する
      await user.getIdToken(true);
      completed();
    }
  }, 1000);
  try {
    await _sendEmailVerification(user);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error?.code === AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER) {
      // 確認メール送信済みでさらに送信しようとした時のエラー。ひとまず何もしないことにする。
      return;
    }
    throw error;
  }
};

const getProviderBySignInMethod = (method: string) => {
  if (method === SignInMethod.GOOGLE) {
    return new GoogleAuthProvider();
  }
  if (method === SignInMethod.MICROSOFT) {
    return new OAuthProvider(SignInMethod.MICROSOFT);
  }
  return undefined;
};

const fetchSignInMethodsForEmail = async (email: string) => {
  const auth = getAuth();
  return _fetchSignInMethodsForEmail(auth, email);
};

// Sign Out
const signOut = () => {
  const auth = getAuth();
  return _signOut(auth);
};

export {
  getCurrentUser,
  onAuthStateChanged,
  sendSignInLinkToEmail,
  sendEmailVerification,
  isSignInWithEmailLink,
  signInWithEmailLink,
  signInWithGoogle,
  signInWithMicrosoft,
  getProviderBySignInMethod,
  signOut,
  fetchSignInMethodsForEmail,
};
