import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '@/contexts/auth';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import type { ResponseError } from '@/lib/api-client';
import { fetcher } from '@/lib/api-client';
import type { SelectMainAccountResponse } from '@/pages/api/main-account-login';
import type { SelectMainAccountRequest } from '@/lib/validation';
import { fetchSignInMethodsForEmail } from '@/lib/firebase-client';
import type { User } from '@/lib/auth';
import type { SessionLoginResponse } from '@/pages/api/session-login';

// 認証状態を取得する
export const useAuthContext = () => useContext(AuthContext);

const userQueryKey = ['user'];

export const useUser = () =>
  useQuery<User, ResponseError>({
    queryKey: userQueryKey,
    queryFn: () => fetcher.get('/api/user'),
  });

// TODO: onErrorによるエラーの見せ方
// FirebaseのIDTokenを使ってAPIでCookieを設定する
export const useLogin = () => {
  const queryClient = useQueryClient();
  return useMutation<SessionLoginResponse, ResponseError, string>({
    mutationFn: (idToken: string) => fetcher.post('/api/session-login', { idToken }),

    onSuccess: ({ email, uid }) => {
      if (email && uid) {
        queryClient.setQueryData(userQueryKey, { email, uid });
      }
    },
  });
};

// 呼び出すとログアウトする
export const useLogoutEffect = () => {
  const user = useAuthContext();
  const [isDoingLogout, setIsDoingLogout] = useState(false);
  const queryClient = useQueryClient();

  useEffect(() => {
    (async () => {
      if (user == null || isDoingLogout) return;

      setIsDoingLogout(true);
      try {
        await fetcher.post('/api/session-logout');
        await queryClient.resetQueries();
      } catch (e) {
        setIsDoingLogout(false);
      }
    })();
  }, [isDoingLogout, queryClient, user]);
};

// メインアカウントを選択し、cookieを書き込む
export const useSelectMainAccount = () => {
  const queryClient = useQueryClient();
  return useMutation<SelectMainAccountResponse, ResponseError, SelectMainAccountRequest>({
    mutationFn: (variables) => fetcher.post('/api/main-account-login', variables),

    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: userQueryKey,
      }),
  });
};

const signInMethodQueryPrefix = 'signInMethod';

export const useSignInMethod = (email: string | undefined) =>
  useQuery<string[]>({
    queryKey: [signInMethodQueryPrefix, email],

    queryFn: () => {
      if (email == null) return [];
      return fetchSignInMethodsForEmail(email);
    },

    enabled: email != null,
  });
