const SERVICE_ORIGIN = process.env.SERVICE_ORIGIN as string;
const VERCEL_ENV = process.env.VERCEL_ENV as string;
const COOKIE_DOMAIN = process.env.COOKIE_DOMAIN as string;
const COOKIE_NAME = process.env.COOKIE_NAME as string;
const MAIN_ACCOUNT_COOKIE_NAME = process.env.MAIN_ACCOUNT_COOKIE_NAME as string;
const FIREBASE_PROJECT_ID = process.env.FIREBASE_PROJECT_ID as string;
const FIREBASE_PRIVATE_KEY = process.env.FIREBASE_PRIVATE_KEY as string;
const FIREBASE_CLIENT_EMAIL = process.env.FIREBASE_CLIENT_EMAIL as string;
const SENDGRID_API_BASE_URL = process.env.SENDGRID_API_BASE_URL as string;
const SENDGRID_API_KEY = process.env.SENDGRID_API_KEY as string;
const SENDER_ADDRESS = process.env.SENDER_ADDRESS as string;
const MAIN_ACCOUNT_JWT_RSA256_PUBLIC = process.env.MAIN_ACCOUNT_JWT_RSA256_PUBLIC as string;
const MAIN_ACCOUNT_JWT_RSA256_PRIVATE = process.env.MAIN_ACCOUNT_JWT_RSA256_PRIVATE as string;

export {
  SERVICE_ORIGIN,
  VERCEL_ENV,
  COOKIE_DOMAIN,
  COOKIE_NAME,
  MAIN_ACCOUNT_COOKIE_NAME,
  FIREBASE_PROJECT_ID,
  FIREBASE_PRIVATE_KEY,
  FIREBASE_CLIENT_EMAIL,
  SENDGRID_API_BASE_URL,
  SENDGRID_API_KEY,
  SENDER_ADDRESS,
  MAIN_ACCOUNT_JWT_RSA256_PUBLIC,
  MAIN_ACCOUNT_JWT_RSA256_PRIVATE,
};
