const NEXT_PUBLIC_SERVICE_ORIGIN = process.env.NEXT_PUBLIC_SERVICE_ORIGIN as string;
const NEXT_PUBLIC_ACTION_CODE_URL = process.env.NEXT_PUBLIC_ACTION_CODE_URL as string;
const NEXT_PUBLIC_TRUSTED_DOMAIN = process.env.NEXT_PUBLIC_TRUSTED_DOMAIN as string;
const NEXT_PUBLIC_VERCEL_ENV = process.env.NEXT_PUBLIC_VERCEL_ENV as string;
const NEXT_PUBLIC_FB_URL = process.env.NEXT_PUBLIC_FB_URL as string;
const NEXT_PUBLIC_KV_URL = process.env.NEXT_PUBLIC_KV_URL as string;
const NEXT_PUBLIC_KM_URL = process.env.NEXT_PUBLIC_KM_URL as string;
const NEXT_PUBLIC_PC_URL = process.env.NEXT_PUBLIC_PC_URL as string;
const NEXT_PUBLIC_DC_URL = process.env.NEXT_PUBLIC_DC_URL as string;
const NEXT_PUBLIC_KB_URL = process.env.NEXT_PUBLIC_KB_URL as string;
const NEXT_PUBLIC_KB_API_URL = process.env.NEXT_PUBLIC_KB_API_URL as string;
const NEXT_PUBLIC_BACKEND_URL = process.env.NEXT_PUBLIC_BACKEND_URL as string;

const NEXT_PUBLIC_FB_INTRODUCTION_FORM_URL = process.env.NEXT_PUBLIC_FB_INTRODUCTION_FORM_URL as string;
const NEXT_PUBLIC_KV_INTRODUCTION_FORM_URL = process.env.NEXT_PUBLIC_KV_INTRODUCTION_FORM_URL as string;
const NEXT_PUBLIC_KM_INTRODUCTION_FORM_URL = process.env.NEXT_PUBLIC_KM_INTRODUCTION_FORM_URL as string;
const NEXT_PUBLIC_PC_INTRODUCTION_FORM_URL = process.env.NEXT_PUBLIC_PC_INTRODUCTION_FORM_URL as string;
const NEXT_PUBLIC_DC_INTRODUCTION_FORM_URL = process.env.NEXT_PUBLIC_DC_INTRODUCTION_FORM_URL as string;
const NEXT_PUBLIC_KB_INTRODUCTION_FORM_URL = process.env.NEXT_PUBLIC_KB_INTRODUCTION_FORM_URL as string;

const NEXT_PUBLIC_AMPLITUDE_API_KEY = process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY as string;

export {
  NEXT_PUBLIC_SERVICE_ORIGIN,
  NEXT_PUBLIC_ACTION_CODE_URL,
  NEXT_PUBLIC_TRUSTED_DOMAIN,
  NEXT_PUBLIC_VERCEL_ENV,
  NEXT_PUBLIC_FB_URL,
  NEXT_PUBLIC_KV_URL,
  NEXT_PUBLIC_KM_URL,
  NEXT_PUBLIC_PC_URL,
  NEXT_PUBLIC_DC_URL,
  NEXT_PUBLIC_KB_URL,
  NEXT_PUBLIC_KB_API_URL,
  NEXT_PUBLIC_BACKEND_URL,
  NEXT_PUBLIC_FB_INTRODUCTION_FORM_URL,
  NEXT_PUBLIC_KV_INTRODUCTION_FORM_URL,
  NEXT_PUBLIC_KM_INTRODUCTION_FORM_URL,
  NEXT_PUBLIC_PC_INTRODUCTION_FORM_URL,
  NEXT_PUBLIC_DC_INTRODUCTION_FORM_URL,
  NEXT_PUBLIC_KB_INTRODUCTION_FORM_URL,
  NEXT_PUBLIC_AMPLITUDE_API_KEY,
};
