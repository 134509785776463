import { GraphQLClient, RequestOptions } from 'graphql-request';
import gql from 'graphql-tag';
import { graphql, type GraphQLResponseResolver, type RequestHandlerOptions } from 'msw';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
type GraphQLClientRequestHeaders = RequestOptions['requestHeaders'];
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
};

/** アカウント */
export type Account = AccountInterface & {
  __typename?: 'Account';
  company: Scalars['String']['output'];
  /** 小文字化されたメールアドレス */
  email: Scalars['String']['output'];
  /** アカウントに紐づくkintoneアカウントの登録情報。未登録の場合など、得られないこともあるので注意 */
  kintoneSetting?: Maybe<KintoneSetting>;
  name: Scalars['String']['output'];
  plan: Plan;
  /** DB内の生のid。adminとの連携など生のidを参照したいときに使用する。refetch用には使えない。 */
  rawId: Scalars['String']['output'];
  /** ユーザーの登録日時を示すISO8601文字列。製品によってはcreatedAtだが、registeredAtに統一する。 */
  registeredAt: Scalars['String']['output'];
  /** 環境内の時間指定出力設定の数 */
  regularOutputConfigCount: Scalars['Int']['output'];
  subAccount?: Maybe<SubAccount>;
  /** planがお試し中の時に入るお試し終了期日を示すISO8601文字列 */
  trialExpiresAt?: Maybe<Scalars['String']['output']>;
};

/**
 * アカウントの共通型。
 * Toyokukumo kintoneAppアカウントでは複数製品のAccountを扱うため共通の型を定義する。
 */
export type AccountInterface = {
  /** 製品によってはDBのスキーマ上だとnullableなので、nullを返さないように注意する */
  company: Scalars['String']['output'];
  /** 小文字化されたメールアドレス */
  email: Scalars['String']['output'];
  name: Scalars['String']['output'];
  plan: Plan;
  /** DB内の生のid。adminとの連携など生のidを参照したいときに使用する。refetch用には使えない。 */
  rawId: Scalars['String']['output'];
  /** ユーザーの登録日時を示すISO8601文字列。製品によってはcreatedAtだが、registeredAtに統一する。 */
  registeredAt: Scalars['String']['output'];
  /** planがお試し中の時に入るお試し終了期日を示すISO8601文字列 */
  trialExpiresAt?: Maybe<Scalars['String']['output']>;
};

export type AddPageFromOtherBookInput = {
  /** 追加元のLedgerBookのid */
  originalLedgerBookId: Scalars['Int']['input'];
  /** 追加したいページ番号 */
  pages: Array<Scalars['Int']['input']>;
  /** 追加先LedgerBookのid */
  targetLedgerBookId: Scalars['Int']['input'];
};

/** サブ管理者を追加するリクエストをした時の戻り値 */
export type AddSubAccountPayload = SubAccount | UserError;

export type DeletePagesInput = {
  /** LedgerBookのid */
  ledgerBookId: Scalars['Int']['input'];
  /** 削除するページ番号 */
  pages: Array<Scalars['Int']['input']>;
};

/** エラーの詳細。どの箇所でどんなエラーが出ているか。 */
export type ErrorField = {
  __typename?: 'ErrorField';
  message: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

/** 招待中のステータス定義 */
export enum InvitationStatus {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
}

/**
 * 登録したkintoneアプリ
 * kintone_appテーブルに対応している
 */
export type KintoneApp = {
  __typename?: 'KintoneApp';
  /** kintone上のアプリのid */
  appId: Scalars['Int']['output'];
  /** kintoneのゲストスペースid。nullは非ゲストスペースであることを示す。 */
  guestSpaceId?: Maybe<Scalars['Int']['output']>;
  /** kintone上での挙動の設定 */
  kintoneJsConfig?: Maybe<KintoneJsConfig>;
  /** 帳票初期選択 */
  layoutInitialSelects: Array<LayoutInitialSelect>;
  /** 帳票 */
  ledgerBooks: Array<LedgerBook>;
  /** 主キー。kintone_appテーブル上のidに一致する */
  rawId: Scalars['Int']['output'];
  /** 並び順 */
  sort: Scalars['Int']['output'];
  /** テーブル行数条件分岐 */
  tableConditions: Array<TableCondition>;
  /** PrintCreatorに登録されているアプリの管理名。未設定の場合は空文字を返す */
  title: Scalars['String']['output'];
  /** 一括出力が有効かどうか */
  usePackPdf: Scalars['Boolean']['output'];
};

/** kintoneAppクエリの引数 */
export type KintoneAppInput = {
  kintoneAppId: Scalars['Int']['input'];
};

/**
 * 一覧ページなどで複数取得しても負荷的に問題が無いと考えられるアプリ情報の型
 * kintone_appテーブルに対応している
 * 論理削除されているものも取得できる
 */
export type KintoneAppSummary = {
  __typename?: 'KintoneAppSummary';
  /** kintone上のアプリのid */
  appId: Scalars['Int']['output'];
  /** kintoneのゲストスペースid。nullは非ゲストスペースであることを示す。 */
  guestSpaceId?: Maybe<Scalars['Int']['output']>;
  /** 主キー。kintone_appテーブル上のidに一致する */
  id: Scalars['Int']['output'];
  /** 削除されているかどうか。論理削除を利用している点に注意 */
  isDeleted: Scalars['Boolean']['output'];
  /** PrintCreatorに登録されているアプリの管理名。未設定の場合は空文字を返す */
  title: Scalars['String']['output'];
};

/**
 * kintone上での挙動の設定
 * kintone_js_configテーブルに対応している
 */
export type KintoneJsConfig = {
  __typename?: 'KintoneJsConfig';
  /** 自動保存を利用するかどうか */
  isUseAutoSave: Scalars['Boolean']['output'];
  /** 帳票初期選択を利用するかどうか */
  isUseLayoutInitialSelect: Scalars['Boolean']['output'];
  /** テーブル行数条件分岐を利用するかどうか */
  isUseTableCondition: Scalars['Boolean']['output'];
  /** 主キー。テーブル上のidに一致する */
  rawId: Scalars['Int']['output'];
};

/** kintone接続情報 */
export type KintoneSetting = {
  __typename?: 'KintoneSetting';
  /** Basic認証が有効かどうか */
  basicAuthEnabled: Scalars['Boolean']['output'];
  /** Basic認証のユーザー名 */
  basicAuthUsername?: Maybe<Scalars['String']['output']>;
  /** 契約kintoneのドメイン */
  domain: Scalars['String']['output'];
  /** 内部的にkintone接続情報が存在するかどうかのみに使用。これで refetch したりurlに埋め込みたくなったらGIDに変えること。 */
  id: Scalars['Int']['output'];
  /** 契約kintoneのサブドメイン */
  subdomain: Scalars['String']['output'];
};

/**
 * 帳票初期選択
 * layout_initial_selectテーブルに対応している
 */
export type LayoutInitialSelect = {
  __typename?: 'LayoutInitialSelect';
  /** フィールドコード */
  code: Scalars['String']['output'];
  /** 比較する値 */
  expectedValue: Scalars['String']['output'];
  /** 条件に一致するときに選択されるLedgerBookのid */
  ledgerBookId?: Maybe<Scalars['Int']['output']>;
  /** 主キー。テーブル上のidに一致する */
  rawId: Scalars['Int']['output'];
  /** 条件に一致するときに選択されるTableConditionのid */
  tableConditionId?: Maybe<Scalars['Int']['output']>;
};

/**
 * 帳票
 * ledger_bookテーブルに対応している
 */
export type LedgerBook = {
  __typename?: 'LedgerBook';
  /** 出力ボタンの設定 */
  isShownOnKintone: Scalars['Boolean']['output'];
  /** bookに含まれるページ */
  ledgerSheetRelations: Array<LedgerSheetRelation>;
  /** 主キー。テーブル上のidに一致する */
  rawId: Scalars['Int']['output'];
  /** 時間指定出力の設定 */
  regularOutputConfigs: Array<RegularOutputConfig>;
  /** 並び順 */
  sort: Scalars['Int']['output'];
  /** 帳票名 */
  title: Scalars['String']['output'];
};

/**
 * アプリに対して複数登録することが出来る帳票の情報のうち１つを表す型
 * ledger_bookテーブルに対応している
 * 論理削除されたものも含む
 */
export type LedgerBookForHistory = {
  __typename?: 'LedgerBookForHistory';
  /** 削除されているかどうか。論理削除を利用している点に注意 */
  isDeleted: Scalars['Boolean']['output'];
  /** 帳票の元となるkintoneアプリ */
  kintoneApp: KintoneAppSummary;
  /** 主キー。ledger_sheetテーブル上のidに一致する */
  rawId: Scalars['Int']['output'];
  /** 帳票の名前 */
  title: Scalars['String']['output'];
};

/** ledgerBookクエリの引数 */
export type LedgerBookInput = {
  kintoneAppId: Scalars['Int']['input'];
  ledgerBookId: Scalars['Int']['input'];
};

/**
 * 帳票の1ページ
 * ledger_sheetテーブルに対応している
 */
export type LedgerSheet = {
  __typename?: 'LedgerSheet';
  /** 作成日時 (ISO8601) */
  createdAt: Scalars['String']['output'];
  /** 用紙の縦幅 */
  height?: Maybe<Scalars['Int']['output']>;
  /** 用紙サイズがその他のサイズかどうか */
  isUserSpecifiedSize: Scalars['Boolean']['output'];
  /** ラベルの位置(ラベル帳票のみで利用) */
  labelBottom: Scalars['Int']['output'];
  /** ラベルの高さ(ラベル帳票のみで利用) */
  labelHeight: Scalars['Int']['output'];
  /** ラベルの位置(ラベル帳票のみで利用) */
  labelLeft: Scalars['Int']['output'];
  /** ラベルの位置(ラベル帳票のみで利用) */
  labelRight: Scalars['Int']['output'];
  /** ラベルの位置(ラベル帳票のみで利用) */
  labelTop: Scalars['Int']['output'];
  /** ラベルの幅 (ラベル帳票のみで利用) */
  labelWidth: Scalars['Int']['output'];
  /** 出力時に背景を利用しない設定 */
  layoutOnly: Scalars['Boolean']['output'];
  /** sheetを利用しているbookの情報 */
  ledgerSheetRelations: Array<LedgerSheetRelation>;
  /** ページの向き */
  orientation: SheetOrientation;
  /** PDFのファイルパス */
  pdfFileName?: Maybe<Scalars['String']['output']>;
  /** ファイルサイズ */
  pdfSize: Scalars['Int']['output'];
  /** 主キー。ledger_sheetテーブル上のidに一致する */
  rawId: Scalars['Int']['output'];
  /** 帳票の種類 */
  sheetType: SheetType;
  /** ページのサイズ */
  size: Scalars['String']['output'];
  /** サムネイルのファイルパス */
  thumbnailFileName?: Maybe<Scalars['String']['output']>;
  /** サムネイルの高さ */
  thumbnailHeight: Scalars['Int']['output'];
  /** サムネイルの幅 */
  thumbnailWidth: Scalars['Int']['output'];
  /** 更新日時 (ISO8601) */
  updatedAt: Scalars['String']['output'];
  /** 用紙の横幅 */
  width?: Maybe<Scalars['Int']['output']>;
};

/**
 * アプリに対して複数登録することが出来る帳票の情報のうち１つを表す型
 * ledger_sheetテーブルに対応している
 * 論理削除されたものも含む
 */
export type LedgerSheetForHistory = {
  __typename?: 'LedgerSheetForHistory';
  /** 主キー。ledger_sheetテーブル上のidに一致する */
  id: Scalars['Int']['output'];
  /** 削除されているかどうか。論理削除を利用している点に注意 */
  isDeleted: Scalars['Boolean']['output'];
  /** 帳票の元となるkintoneアプリ */
  kintoneApp: KintoneAppSummary;
  /** 帳票の名前 */
  title: Scalars['String']['output'];
};

export type LedgerSheetRelation = {
  __typename?: 'LedgerSheetRelation';
  /** 帳票 */
  ledgerBook: LedgerBook;
  /** 帳票のページ */
  ledgerSheet: LedgerSheet;
  /**
   * ページ番号
   * 最初のページは1
   */
  page: Scalars['Int']['output'];
};

/** アカウントのうち、サブ管理者から参照されるときのアカウント定義 */
export type MainAccount = AccountInterface & {
  __typename?: 'MainAccount';
  company: Scalars['String']['output'];
  email: Scalars['String']['output'];
  /** アカウントに紐づくkintoneアカウントの登録情報。未登録の場合など、得られないこともあるので注意 */
  kintoneSetting?: Maybe<KintoneSetting>;
  name: Scalars['String']['output'];
  plan: Plan;
  /** DB内の生のid。adminとの連携など生のidを参照したいときに使用する。refetch用には使えない。 */
  rawId: Scalars['String']['output'];
  /** ユーザーの登録日時を示すISO8601文字列。製品によってはcreatedAtだが、registeredAtに統一する。 */
  registeredAt: Scalars['String']['output'];
  /** planがお試し中の時に入るお試し終了期日を示すISO8601文字列 */
  trialExpiresAt?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /**
   * 別の帳票のページを指定した帳票に追加する
   * role: account
   */
  addPageFromOtherBook?: Maybe<UserError>;
  /**
   * 自分自身のアドレスで持っている環境にサブ管理者を追加する。
   * 自分自身のアドレスと同じメールアドレスでは追加できない。
   * 追加したサブ管理者は PENDING として追加され、account.kintoneapp.comで承諾することで認可される。
   * メインアカウントは関係がないことに注意
   * role :has-main-account
   */
  addSubAccount: AddSubAccountPayload;
  /**
   * 自分自身のアドレスがサブ管理者である環境に対して、招待の承諾を行う
   * サブ管理者のステータスをPENDINGからAPPROVEDに変更する。
   *
   * role :authenticated
   * mainAccountEmail: 自分自身を招待しているメインアカウントのemail
   */
  approveSubAccount: UpdateSubAccountPayload;
  /**
   * LedgerBook内のページを削除する
   * role: account
   */
  deletePages?: Maybe<UserError>;
  /**
   * 自分自身のアドレスで持っている環境のサブ管理者を削除する。
   * 削除対象が存在しない場合にUserErrorが返る
   * メインアカウントは関係がないことに注意
   * role :has-main-account
   */
  deleteSubAccount?: Maybe<UserError>;
  /**
   * 自分自身のアドレスがサブ管理者である環境に対して、招待の辞退を行う
   * サブ管理者のステータスをPENDINGからREJECTEDに変更する。
   *
   * role :authenticated
   * mainAccountEmail: 自分自身を招待しているメインアカウントのemail
   */
  rejectSubAccount: UpdateSubAccountPayload;
  /**
   * kintone上の出力ボタンの設定
   * role: account
   */
  setShownOnKintone?: Maybe<UserError>;
  /**
   * LedgerBook内のページを並び替える
   * role: account
   */
  sortPages?: Maybe<UserError>;
  /**
   * 自分自身の所有している環境のアカウントの所有者をサブ管理者に変更する
   * 元の自分自身はサブ管理者になる
   * 既にサブ管理者がサブ管理者自身の環境を持っていた場合は変更できない
   *
   * role :has-main-account
   */
  swapOwnerWithSubAccount?: Maybe<UserError>;
};

export type MutationAddPageFromOtherBookArgs = {
  input: AddPageFromOtherBookInput;
};

export type MutationAddSubAccountArgs = {
  email: Scalars['String']['input'];
};

export type MutationApproveSubAccountArgs = {
  mainAccountEmail: Scalars['String']['input'];
};

export type MutationDeletePagesArgs = {
  input: DeletePagesInput;
};

export type MutationRejectSubAccountArgs = {
  mainAccountEmail: Scalars['String']['input'];
};

export type MutationSetShownOnKintoneArgs = {
  input: SetShownOnKintoneInput;
};

export type MutationSortPagesArgs = {
  input: SortPagesInput;
};

/**
 * 帳票からの一括出力の履歴を表す型
 * pack_pdf_logテーブルに対応している
 */
export type PackPdfLog = {
  __typename?: 'PackPdfLog';
  /** 履歴が作成された日時を示すISO8601文字列 */
  createdAt: Scalars['String']['output'];
  /** 作成したユーザー名を示す */
  createdBy: Scalars['String']['output'];
  /** 成果物のダウンロードURL。出力に成功し、期限切れではない場合のみ得られる */
  downloadUrl?: Maybe<Scalars['String']['output']>;
  /** エラーがある場合に、ユーザー向けのエラーメッセージが入る */
  errorMessage?: Maybe<Scalars['String']['output']>;
  /** 帳票の作成が完了した日時を示すISO8601文字列 */
  generatedAt?: Maybe<Scalars['String']['output']>;
  /** 主キー。pack_pdf_logテーブル上のidに一致する */
  id: Scalars['Int']['output'];
  /** 生成日時からpack_pdf_logのtermから得られる期間が過ぎた場合にtrueが返される */
  isDownloadExpired: Scalars['Boolean']['output'];
  /** 出力中か否か */
  isGenerating: Scalars['Boolean']['output'];
  /** 出力に用いた帳票のオブジェクト */
  ledgerBookForHistory: LedgerBookForHistory;
  /** 出力に用いた帳票のオブジェクト */
  ledgerSheetForHistory: LedgerSheetForHistory;
  /** 出力した枚数 */
  pageCount: Scalars['Int']['output'];
};

/**
 * 帳票からの単一のPDF出力履歴を表す型
 * pdf_logテーブルに対応している
 */
export type PdfLog = {
  __typename?: 'PdfLog';
  /** 履歴が作成された日時を示すISO8601文字列 */
  createdAt: Scalars['String']['output'];
  /**
   * 作成したユーザー名を示す
   * kViewer連携などのときに、nullになることがある
   */
  createdBy?: Maybe<Scalars['String']['output']>;
  /** 主キー。pdf_logテーブル上のidに一致する */
  id: Scalars['Int']['output'];
  /** 出力に用いた帳票のオブジェクト */
  ledgerBookForHistory: LedgerBookForHistory;
  /** 出力に用いた帳票のオブジェクト */
  ledgerSheetForHistory: LedgerSheetForHistory;
  /**
   * 履歴がどのような方法で作られたかを示す
   * 例) KINTONE_DETAIL, KVIEWER
   */
  outputType: PrintHistoryOutputType;
  /** 個別出力の場合に、出力に利用したkintoneのレコード番号が入る */
  recordId: Scalars['Int']['output'];
};

/** 全kintone連携製品共通の型定義 */
export enum Plan {
  Light = 'LIGHT',
  Premium = 'PREMIUM',
  Professional = 'PROFESSIONAL',
  Standard = 'STANDARD',
  Trial = 'TRIAL',
}

/**
 * printHistoriesクエリの引数
 * 絞り込み条件やソートの仕方を表す
 */
export type PrintHistoriesInput = {
  /** PDF出力か、一括出力か、またはその両方を取得するのかを指定する */
  historyType: PrintHistoryType;
  /** アプリによって絞り込む場合に指定するkintoneAppのid */
  kintoneAppId?: InputMaybe<Scalars['Int']['input']>;
  /** 表示するページ数。1ページ当たり20件の結果を返す */
  page: Scalars['Int']['input'];
  /** 昇順か降順か */
  sortDirection: SortDirection;
  /** 取得する履歴をソートする基準 */
  sortKey: PrintHistorySortKey;
};

/** printHistoriesクエリの戻り値 */
export type PrintHistoriesPayload = PrintHistoriesResult | UserError;

/** printHistoriesクエリの結果に使う型 */
export type PrintHistoriesResult = {
  __typename?: 'PrintHistoriesResult';
  /** 絞り込み条件に当てはまる履歴の全体数から考えたページ数 */
  pageCount: Scalars['Int']['output'];
  /** 取得した履歴の配列 */
  result: Array<PrintHistory>;
};

/** 一括出力履歴とPDF出力履歴を同時に返す場合に使うためのunion型 */
export type PrintHistory = PackPdfLog | PdfLog;

/** 出力履歴の種類 */
export enum PrintHistoryOutputType {
  KintoneDetail = 'KINTONE_DETAIL',
  Kviewer = 'KVIEWER',
}

/** printHistoriesクエリでのソートおいて、どのキーでソートするかを表すenum */
export enum PrintHistorySortKey {
  /** 作成日時 */
  CreatedAt = 'CREATED_AT',
  /** 作成者 */
  CreatorName = 'CREATOR_NAME',
  /** <アプリ名>: <帳票名>の形式 */
  KintoneappNameAndLedgerSheetName = 'KINTONEAPP_NAME_AND_LEDGER_SHEET_NAME',
}

/** PDF出力か、一括出力か、その両方を取得するかを指定するために使うenum */
export enum PrintHistoryType {
  All = 'ALL',
  PackPdfLogs = 'PACK_PDF_LOGS',
  PdfLogs = 'PDF_LOGS',
}

export type Query = {
  __typename?: 'Query';
  /**
   * 自分自身のアドレスに紐づくアカウント情報を取得する
   * アカウントの選択は関係ないことに注意が必要
   * メインアカウントは関係がないことに注意
   * role :authenticated
   */
  account?: Maybe<Account>;
  /**
   * kintoneAppをidで指定して取得する
   * role :account
   */
  kintoneApp?: Maybe<KintoneApp>;
  /**
   * ユーザーが登録しているkintoneアプリを全て取得する
   * 削除してから90日以上経ったものは取得されない
   * role :account
   */
  kintoneAppSummaries: Array<KintoneAppSummary>;
  /**
   * ledgerBookをidで指定して取得する
   * role :account
   */
  ledgerBook?: Maybe<LedgerBook>;
  /**
   * アカウント選択で選択したアカウント情報を取得する
   * role :account
   */
  mainAccount: Account;
  /**
   * 自分自身のアドレスがサブ管理者であるリストを取得する
   * サブ管理者を登録したメインアカウントのプランは無料お試しかつ無料お試し期間内または契約済
   * 招待未承諾であるものだけ
   * メインアカウントは関係がないことに注意
   * role :authenticated
   */
  pendingSubAccounts: Array<SubAccount>;
  /**
   * 自身が所有するアプリに紐づくすべての出力履歴を絞り込みを使いながら参照することが出来る
   * 不正な入力の場合はUserErrorが返される。詳しくはPrintHistoriesInputを直接参照すること
   * role :account
   */
  printHistories: PrintHistoriesPayload;
  /**
   * regularOutputConfigをidで指定して取得する
   * role :account
   */
  regularOutputConfig?: Maybe<RegularOutputConfig>;
  /**
   * 自分自身のアドレスがサブ管理者であるリストを取得する
   * サブ管理者を登録したメインアカウントのプランは無料お試しかつ無料お試し期間内または契約済
   * 招待承諾済であるものだけ
   * メインアカウントは関係がないことに注意
   * role :authenticated
   */
  subAccounts: Array<SubAccount>;
};

export type QueryKintoneAppArgs = {
  input: KintoneAppInput;
};

export type QueryLedgerBookArgs = {
  input: LedgerBookInput;
};

export type QueryPrintHistoriesArgs = {
  input: PrintHistoriesInput;
};

export type QueryRegularOutputConfigArgs = {
  input: RegularOutputConfigInput;
};

export type RecurrenceRule = {
  __typename?: 'RecurrenceRule';
  /** 出力処理をMONTHLYで実行する場合の実行日。1−31または-1。-1は月末最終日を示す */
  scheduleDate?: Maybe<Scalars['Int']['output']>;
  /** 出力処理を実行する時間 0−23 */
  scheduleHour: Scalars['Int']['output'];
  /** 出力処理を実行する間隔 */
  scheduleInterval: ScheduleInterval;
  /** 出力処理をDAILYで出力処理を実行する日 */
  schedulePattern?: Maybe<SchedulePattern>;
  /** 出力処理をWEEKLYで実行する場合の出力処理を実行する曜日 */
  scheduleWeek?: Maybe<ScheduleWeek>;
};

export type RegularOutputConfig = {
  __typename?: 'RegularOutputConfig';
  /** レコードの絞り込み設定 */
  condition: RegularOutputConfigCondition;
  /** 追加保存をするときの保存先添付ファイルフィールドのフィールドコード */
  historyFileFieldCode: Scalars['String']['output'];
  /** 追加保存をするときのファイル名 */
  historyFilename: Scalars['String']['output'];
  /** 追加保存を利用するかどうか */
  isUseHistorySave: Scalars['Boolean']['output'];
  /** 入替え保存を利用するかどうか */
  isUseLatestSave: Scalars['Boolean']['output'];
  /** 入替え保存を利用するときの保存先添付ファイルフィールドのフィールドコード */
  latestFileFieldCode: Scalars['String']['output'];
  /** 入替え保存をするときのファイル名 */
  latestFilename: Scalars['String']['output'];
  /** 最後に成功した履歴 */
  latestSuccessLog?: Maybe<RegularOutputLog>;
  /** 主キー。テーブル上のidに一致する */
  rawId: Scalars['Int']['output'];
  /** 実行する間隔のRRULEをパースしたもの */
  recurrenceRule: RecurrenceRule;
  /** 実行する間隔をRRULEで表現したもの */
  scheduleRrule: Scalars['String']['output'];
};

export type RegularOutputConfigCondition = {
  __typename?: 'RegularOutputConfigCondition';
  /** レコードの絞り込み方法がqueryのとき、指定されるクエリ */
  kintoneQuery: Scalars['String']['output'];
  /** レコードの絞り込み条件がSelectionのとき、Ruleをつなげる条件 */
  logic: RegularOutputConfigConditionLogic;
  /** 比較条件 */
  rules: Array<RegularOutputConfigConditionRule>;
  /** レコードの絞り込み方法 */
  type: RegularOutputConfigConditionType;
};

/** レコードの絞り込み条件がSelectionのとき、RuleをAndでつなげるかORでつなげるか */
export enum RegularOutputConfigConditionLogic {
  And = 'and',
  Or = 'or',
}

export type RegularOutputConfigConditionRule = {
  __typename?: 'RegularOutputConfigConditionRule';
  /** 比較条件として利用するフィールドのフィールドコード */
  kintoneFieldCode: Scalars['String']['output'];
  /** 比較条件として利用するフィールドのタイプ */
  kintoneFieldType: Scalars['String']['output'];
  /** 主キー。テーブル上のidに一致する */
  rawId: Scalars['Int']['output'];
  /** フィールドの比較条件。EQやLTEなど */
  sign: Scalars['String']['output'];
  /** フィールドと比較される文字列 */
  value: Scalars['String']['output'];
};

/** レコードを絞り込むための条件の指定方法 */
export enum RegularOutputConfigConditionType {
  All = 'all',
  Query = 'query',
  Selection = 'selection',
}

/** regularOutputConfigクエリの引数 */
export type RegularOutputConfigInput = {
  regularOutputConfigId: Scalars['Int']['input'];
};

export type RegularOutputLog = {
  __typename?: 'RegularOutputLog';
  /** 処理終了日時 (ISO8601文字列) */
  endProcessing?: Maybe<Scalars['String']['output']>;
  /** kintoneのクエリ */
  kintoneQuery: Scalars['String']['output'];
  /** 主キー。テーブル上のid (bigint)に一致する */
  rawId: Scalars['String']['output'];
  /** レコード数 */
  recordCount: Scalars['Int']['output'];
  /** 処理開始日時 (ISO8601文字列) */
  startProcessing?: Maybe<Scalars['String']['output']>;
  /** 生成の状態 */
  status: RegularOutputLogStatus;
};

export enum RegularOutputLogStatus {
  Failure = 'failure',
  Processing = 'processing',
  Success = 'success',
  Unexecuted = 'unexecuted',
}

export enum ScheduleInterval {
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY',
}

export enum SchedulePattern {
  /** 平日のみ */
  EveryWeekday = 'everyWeekday',
  /** 毎日 */
  Everyday = 'everyday',
}

export enum ScheduleWeek {
  Fr = 'FR',
  Mo = 'MO',
  Sa = 'SA',
  Su = 'SU',
  Th = 'TH',
  Tu = 'TU',
  We = 'WE',
}

export type SetShownOnKintoneInput = {
  /** 帳票のid */
  ledgerBookId: Scalars['Int']['input'];
  /** 表示するかどうか */
  value: Scalars['Boolean']['input'];
};

export enum SheetOrientation {
  /** 横 */
  L = 'L',
  /** 縦 */
  P = 'P',
}

export enum SheetType {
  Label = 'label',
  Multi = 'multi',
  Recordstbl = 'recordstbl',
  Single = 'single',
}

/**
 * 昇順か降順かを表すenum
 * 引数でソートをコントロールできるクエリ全般に使う
 */
export enum SortDirection {
  /** 昇順 */
  Asc = 'ASC',
  /** 降順 */
  Desc = 'DESC',
}

export type SortPagesInput = {
  /** LedgerBookのid */
  ledgerBookId: Scalars['Int']['input'];
  /** 並び替えるデータ */
  pages: Array<Scalars['Int']['input']>;
};

/**
 * サブ管理者
 * アカウントは1つだけサブ管理者を設定できる
 */
export type SubAccount = {
  __typename?: 'SubAccount';
  email: Scalars['String']['output'];
  mainAccount: MainAccount;
  status: InvitationStatus;
};

/**
 * テーブル行数条件分岐
 * table_conditionテーブルに対応している
 */
export type TableCondition = {
  __typename?: 'TableCondition';
  /** フィールドコード */
  code: Scalars['String']['output'];
  /** 表示名 */
  name: Scalars['String']['output'];
  /** 主キー。テーブル上のidに一致する */
  rawId: Scalars['Int']['output'];
  /** 条件 */
  tableConditionRules: Array<TableConditionRule>;
};

/**
 * テーブル行数分岐のルール
 * table_condition_ruleテーブルに対応している
 */
export type TableConditionRule = {
  __typename?: 'TableConditionRule';
  /** 行数の条件 */
  end: Scalars['Int']['output'];
  /** 条件に一致するときに選択されるLedgerBookのid */
  ledgerBookId: Scalars['Int']['output'];
  /** 主キー。テーブル上のidに一致する */
  rawId: Scalars['Int']['output'];
  /** 行数の条件 */
  start: Scalars['Int']['output'];
};

/** 招待されているサブ管理者の承諾または辞退をした時の戻り値 */
export type UpdateSubAccountPayload = SubAccount | UserError;

/**
 * ユーザーの入力によって生じたエラーを示す
 * 原則としてユーザーの入力を修正することでリクエストは成功する可能性がある
 * バリデーションエラーなどドメインロジックに関係するエラー、復帰可能なエラー、などを伴う処理はこれの union を返す
 * mutaion では基本的に使うべき
 * query では引数の検証などが必要な時は使うべき。引数が無い場合は使わないこともある
 */
export type UserError = {
  __typename?: 'UserError';
  /**
   * エラーの種別。主にプログラムで処理を分ける用
   * TODO: 決まってきたら Enum にする
   */
  code: Scalars['String']['output'];
  /**
   * エラーの詳細の一覧。
   * 例： [{ name: "email", message: "emailの仕様に合ってません" }, { name: token, message: "期限切れです" }]
   */
  fields: Array<ErrorField>;
  /**
   * ユーザーに見せるためのエラーメッセージ
   * 例: 入力されたEmailが正しくありません。
   */
  message: Scalars['String']['output'];
};

export type AddSubAccountMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;

export type AddSubAccountMutation = {
  __typename?: 'Mutation';
  addSubAccount:
    | { __typename: 'SubAccount'; email: string }
    | {
        __typename: 'UserError';
        code: string;
        message: string;
        fields: Array<{ __typename?: 'ErrorField'; name: string; message: string }>;
      };
};

export type ApproveSubAccountMutationVariables = Exact<{
  mainAccountEmail: Scalars['String']['input'];
}>;

export type ApproveSubAccountMutation = {
  __typename?: 'Mutation';
  approveSubAccount: { __typename: 'SubAccount'; email: string } | { __typename: 'UserError'; message: string };
};

export type DeleteSubAccountMutationVariables = Exact<{ [key: string]: never }>;

export type DeleteSubAccountMutation = {
  __typename?: 'Mutation';
  deleteSubAccount?: { __typename: 'UserError'; code: string; message: string } | null;
};

export type MainAndSubAccountsQueryVariables = Exact<{ [key: string]: never }>;

export type MainAndSubAccountsQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    rawId: string;
    name: string;
    email: string;
    plan: Plan;
    company: string;
    trialExpiresAt?: string | null;
    registeredAt: string;
    subAccount?: { __typename?: 'SubAccount'; email: string; status: InvitationStatus } | null;
  } | null;
  subAccounts: Array<{
    __typename?: 'SubAccount';
    email: string;
    mainAccount: { __typename?: 'MainAccount'; email: string; plan: Plan; trialExpiresAt?: string | null };
  }>;
};

export type PendingSubAccountsQueryVariables = Exact<{ [key: string]: never }>;

export type PendingSubAccountsQuery = {
  __typename?: 'Query';
  pendingSubAccounts: Array<{
    __typename?: 'SubAccount';
    email: string;
    status: InvitationStatus;
    mainAccount: { __typename?: 'MainAccount'; email: string; plan: Plan; trialExpiresAt?: string | null };
  }>;
};

export type RejectSubAccountMutationVariables = Exact<{
  mainAccountEmail: Scalars['String']['input'];
}>;

export type RejectSubAccountMutation = {
  __typename?: 'Mutation';
  rejectSubAccount: { __typename: 'SubAccount'; email: string } | { __typename: 'UserError'; message: string };
};

export type SwapOwnerWithSubAccountMutationVariables = Exact<{ [key: string]: never }>;

export type SwapOwnerWithSubAccountMutation = {
  __typename?: 'Mutation';
  swapOwnerWithSubAccount?: {
    __typename: 'UserError';
    code: string;
    message: string;
    fields: Array<{ __typename?: 'ErrorField'; name: string; message: string }>;
  } | null;
};

export const AddSubAccountDocument = gql`
  mutation addSubAccount($email: String!) {
    addSubAccount(email: $email) {
      __typename
      ... on SubAccount {
        email
      }
      ... on UserError {
        code
        message
        fields {
          name
          message
        }
      }
    }
  }
`;
export const ApproveSubAccountDocument = gql`
  mutation approveSubAccount($mainAccountEmail: String!) {
    approveSubAccount(mainAccountEmail: $mainAccountEmail) {
      __typename
      ... on SubAccount {
        email
      }
      ... on UserError {
        message
      }
    }
  }
`;
export const DeleteSubAccountDocument = gql`
  mutation deleteSubAccount {
    deleteSubAccount {
      __typename
      code
      message
    }
  }
`;
export const MainAndSubAccountsDocument = gql`
  query mainAndSubAccounts {
    account {
      rawId
      name
      email
      plan
      company
      trialExpiresAt
      registeredAt
      subAccount {
        email
        status
      }
    }
    subAccounts {
      email
      mainAccount {
        email
        plan
        trialExpiresAt
      }
    }
  }
`;
export const PendingSubAccountsDocument = gql`
  query pendingSubAccounts {
    pendingSubAccounts {
      email
      status
      mainAccount {
        email
        plan
        trialExpiresAt
      }
    }
  }
`;
export const RejectSubAccountDocument = gql`
  mutation rejectSubAccount($mainAccountEmail: String!) {
    rejectSubAccount(mainAccountEmail: $mainAccountEmail) {
      __typename
      ... on SubAccount {
        email
      }
      ... on UserError {
        message
      }
    }
  }
`;
export const SwapOwnerWithSubAccountDocument = gql`
  mutation swapOwnerWithSubAccount {
    swapOwnerWithSubAccount {
      __typename
      code
      message
      fields {
        name
        message
      }
    }
  }
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string,
  variables?: any,
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType, _variables) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    addSubAccount(
      variables: AddSubAccountMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<AddSubAccountMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<AddSubAccountMutation>(AddSubAccountDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'addSubAccount',
        'mutation',
        variables,
      );
    },
    approveSubAccount(
      variables: ApproveSubAccountMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ApproveSubAccountMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ApproveSubAccountMutation>(ApproveSubAccountDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'approveSubAccount',
        'mutation',
        variables,
      );
    },
    deleteSubAccount(
      variables?: DeleteSubAccountMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<DeleteSubAccountMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<DeleteSubAccountMutation>(DeleteSubAccountDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'deleteSubAccount',
        'mutation',
        variables,
      );
    },
    mainAndSubAccounts(
      variables?: MainAndSubAccountsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<MainAndSubAccountsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<MainAndSubAccountsQuery>(MainAndSubAccountsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'mainAndSubAccounts',
        'query',
        variables,
      );
    },
    pendingSubAccounts(
      variables?: PendingSubAccountsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<PendingSubAccountsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<PendingSubAccountsQuery>(PendingSubAccountsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'pendingSubAccounts',
        'query',
        variables,
      );
    },
    rejectSubAccount(
      variables: RejectSubAccountMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<RejectSubAccountMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<RejectSubAccountMutation>(RejectSubAccountDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'rejectSubAccount',
        'mutation',
        variables,
      );
    },
    swapOwnerWithSubAccount(
      variables?: SwapOwnerWithSubAccountMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<SwapOwnerWithSubAccountMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<SwapOwnerWithSubAccountMutation>(SwapOwnerWithSubAccountDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'swapOwnerWithSubAccount',
        'mutation',
        variables,
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockAddSubAccountMutation(
 *   ({ query, variables }) => {
 *     const { email } = variables;
 *     return HttpResponse.json({
 *       data: { addSubAccount }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockAddSubAccountMutation = (
  resolver: GraphQLResponseResolver<AddSubAccountMutation, AddSubAccountMutationVariables>,
  options?: RequestHandlerOptions,
) => graphql.mutation<AddSubAccountMutation, AddSubAccountMutationVariables>('addSubAccount', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockApproveSubAccountMutation(
 *   ({ query, variables }) => {
 *     const { mainAccountEmail } = variables;
 *     return HttpResponse.json({
 *       data: { approveSubAccount }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockApproveSubAccountMutation = (
  resolver: GraphQLResponseResolver<ApproveSubAccountMutation, ApproveSubAccountMutationVariables>,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<ApproveSubAccountMutation, ApproveSubAccountMutationVariables>(
    'approveSubAccount',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockDeleteSubAccountMutation(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { deleteSubAccount }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockDeleteSubAccountMutation = (
  resolver: GraphQLResponseResolver<DeleteSubAccountMutation, DeleteSubAccountMutationVariables>,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<DeleteSubAccountMutation, DeleteSubAccountMutationVariables>('deleteSubAccount', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockMainAndSubAccountsQuery(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { account, subAccounts }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockMainAndSubAccountsQuery = (
  resolver: GraphQLResponseResolver<MainAndSubAccountsQuery, MainAndSubAccountsQueryVariables>,
  options?: RequestHandlerOptions,
) => graphql.query<MainAndSubAccountsQuery, MainAndSubAccountsQueryVariables>('mainAndSubAccounts', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockPendingSubAccountsQuery(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { pendingSubAccounts }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockPendingSubAccountsQuery = (
  resolver: GraphQLResponseResolver<PendingSubAccountsQuery, PendingSubAccountsQueryVariables>,
  options?: RequestHandlerOptions,
) => graphql.query<PendingSubAccountsQuery, PendingSubAccountsQueryVariables>('pendingSubAccounts', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockRejectSubAccountMutation(
 *   ({ query, variables }) => {
 *     const { mainAccountEmail } = variables;
 *     return HttpResponse.json({
 *       data: { rejectSubAccount }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockRejectSubAccountMutation = (
  resolver: GraphQLResponseResolver<RejectSubAccountMutation, RejectSubAccountMutationVariables>,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<RejectSubAccountMutation, RejectSubAccountMutationVariables>('rejectSubAccount', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockSwapOwnerWithSubAccountMutation(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { swapOwnerWithSubAccount }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockSwapOwnerWithSubAccountMutation = (
  resolver: GraphQLResponseResolver<SwapOwnerWithSubAccountMutation, SwapOwnerWithSubAccountMutationVariables>,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<SwapOwnerWithSubAccountMutation, SwapOwnerWithSubAccountMutationVariables>(
    'swapOwnerWithSubAccount',
    resolver,
    options,
  );
