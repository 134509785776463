import { z } from 'zod';
import type { ZodIssueOptionalMessage, ErrorMapCtx } from 'zod/lib/ZodError';
import type { useTranslation } from '@/hooks/i18n';

const REQUIRED = '必須入力項目です';
const INVALID_EMAIL = 'メールアドレスの形式が正しくありません';
const INVALID_URL = 'URLの形式が正しくありません';
const MAX_TEXT_LIMIT = (length: number | bigint) => `${length}文字以内で入力してください`;

// サーバーから返ってくるエラーに対して表示するメッセージ
const AUTHORIZATION_ERROR = 'セッションの有効期限が切れました。ブラウザをリロードして再度お試しください。';
const INTERNAL_SERVER_ERROR = 'サーバーでエラーが発生しました。しばらくしてから再度お試しください。';
const UNEXPECTED_ERROR = '予期せぬエラーが発生しました。しばらくしてから再度お試しください。';

// https://github.com/colinhacks/zod/blob/master/ERROR_HANDLING.md#customizing-errors-with-zoderrormap
const serverCustomErrorMap: z.ZodErrorMap = (issue, ctx) => {
  if (issue.code === z.ZodIssueCode.invalid_type) {
    if (issue.received === 'undefined') {
      return { message: REQUIRED };
    }
  }

  if (issue.code === z.ZodIssueCode.invalid_string) {
    if (issue.validation === 'email') {
      return { message: INVALID_EMAIL };
    }
    if (issue.validation === 'url') {
      return { message: INVALID_URL };
    }
  }

  if (issue.code === z.ZodIssueCode.too_big) {
    if (issue.type === 'string') {
      return { message: MAX_TEXT_LIMIT(issue.maximum) };
    }
  }

  return { message: ctx.defaultError };
};

// サーバ側のカスタムエラーメッセージを登録
const setUpServerZod = () => {
  z.setErrorMap(serverCustomErrorMap);
};

const customErrorMap =
  ({ t }: ReturnType<typeof useTranslation>) =>
  (issue: ZodIssueOptionalMessage, ctx: ErrorMapCtx) => {
    if (issue.code === z.ZodIssueCode.invalid_type) {
      if (issue.received === 'undefined') {
        return { message: t('validation.required') as string };
      }
    }

    if (issue.code === z.ZodIssueCode.invalid_string) {
      if (issue.validation === 'email') {
        return { message: t('validation.invalidEmail') as string };
      }
      if (issue.validation === 'url') {
        return { message: t('validation.invalidUrl') as string };
      }
    }

    if (issue.code === z.ZodIssueCode.too_big) {
      if (issue.type === 'string') {
        return { message: t('validation.maxTextLimit', { length: issue.maximum }) as string };
      }
    }

    return { message: ctx.defaultError };
  };

// フロント側のカスタムエラーメッセージを登録
const setUpZod = (translation: ReturnType<typeof useTranslation> | undefined) => {
  if (!translation) return;
  z.setErrorMap(customErrorMap(translation));
};

const sendSwapOwnerRequestSchema = z.object({
  previousOwnerEmail: z.string().email(),
  newOwnerEmail: z.string().email(),
});
type SendSwapOwnerRequest = z.infer<typeof sendSwapOwnerRequestSchema>;

const selectMainAccountRequestSchema = z.object({
  email: z.string().email(),
});
type SelectMainAccountRequest = z.infer<typeof selectMainAccountRequestSchema>;

const sendInvitationRequestSchema = z.object({
  inviterEmail: z.string().email(),
  inviteeEmail: z.string().email(),
});
type SendInvitationRequest = z.infer<typeof sendInvitationRequestSchema>;

const sendInvitationResultRequestSchema = z.object({
  isApprove: z.boolean(),
  inviterEmail: z.string().email(),
  inviteeEmail: z.string().email(),
});
type SendInvitationResultRequest = z.infer<typeof sendInvitationResultRequestSchema>;

const saveAccountRequestSchema = z.object({
  name: z.string().max(64),
  base64EncodedAvatarImage: z.string().nullable(),
  profile: z.string(),
});

type SaveAccountRequest = z.infer<typeof saveAccountRequestSchema>;

export {
  setUpServerZod,
  setUpZod,
  selectMainAccountRequestSchema,
  sendSwapOwnerRequestSchema,
  sendInvitationRequestSchema,
  sendInvitationResultRequestSchema,
  saveAccountRequestSchema,
  AUTHORIZATION_ERROR,
  INTERNAL_SERVER_ERROR,
  UNEXPECTED_ERROR,
};
export type {
  SelectMainAccountRequest,
  SendSwapOwnerRequest,
  SendInvitationRequest,
  SendInvitationResultRequest,
  SaveAccountRequest,
};
