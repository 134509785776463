import { GraphQLClient } from 'graphql-request';
import {
  NEXT_PUBLIC_FB_URL,
  NEXT_PUBLIC_DC_URL,
  NEXT_PUBLIC_KV_URL,
  NEXT_PUBLIC_KM_URL,
  NEXT_PUBLIC_PC_URL,
  NEXT_PUBLIC_BACKEND_URL,
  NEXT_PUBLIC_KB_API_URL,
} from '@/config/env-client';
import { getSdk as getAccountSdk } from '@/generated/account-graphql-client';
import { getSdk as getDcSdk } from '@/generated/dc-graphql-client';
import { getSdk as getFbSdk } from '@/generated/fb-graphql-client';
import { getSdk as getKmSdk } from '@/generated/km-graphql-client';
import { getSdk as getKvSdk } from '@/generated/kv-graphql-client';
import { getSdk as getPcSdk } from '@/generated/pc-graphql-client';
import type { RequestConfig } from 'graphql-request/src/types';

const FB_ENDPOINT = `${NEXT_PUBLIC_FB_URL}/api/graphql`;
const KV_ENDPOINT = `${NEXT_PUBLIC_KV_URL}/api/graphql`;
const KM_ENDPOINT = `${NEXT_PUBLIC_KM_URL}/api/graphql`;
const PC_ENDPOINT = `${NEXT_PUBLIC_PC_URL}/api/graphql`;
const DC_ENDPOINT = `${NEXT_PUBLIC_DC_URL}/api/graphql`;
const KB_ENDPOINT = `${NEXT_PUBLIC_KB_API_URL}/graphql`;
const BACKEND_ENDPOINT = `${NEXT_PUBLIC_BACKEND_URL}/api/graphql`;

const commonOptions: RequestConfig = {
  credentials: 'include',
  mode: 'cors',
};

export const accountSdk = getAccountSdk(new GraphQLClient(BACKEND_ENDPOINT, commonOptions));
export const dcSdk = getDcSdk(new GraphQLClient(DC_ENDPOINT, commonOptions));
export const fbSdk = getFbSdk(new GraphQLClient(FB_ENDPOINT, commonOptions));
export const kmSdk = getKmSdk(new GraphQLClient(KM_ENDPOINT, commonOptions));
export const kvSdk = getKvSdk(new GraphQLClient(KV_ENDPOINT, commonOptions));
export const pcSdk = getPcSdk(new GraphQLClient(PC_ENDPOINT, commonOptions));
export const kbSdk = getFbSdk(new GraphQLClient(KB_ENDPOINT, commonOptions));
