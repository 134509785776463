import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

// リダイレクト中かどうかを判定する
// 外部サイトへのリダイレクトは検出できないことに注意
const useIsRedirecting = () => {
  const router = useRouter();
  const [isRedirecting, setIsRedirecting] = useState(false);
  useEffect(() => {
    const handleStart = () => {
      setIsRedirecting(true);
    };
    const handleStop = () => {
      setIsRedirecting(false);
    };
    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleStop);
    router.events.on('routeChangeError', handleStop);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleStop);
      router.events.off('routeChangeError', handleStop);
    };
  }, [router]);
  return isRedirecting;
};

export { useIsRedirecting };
