import { GraphQLClient, RequestOptions } from 'graphql-request';
import gql from 'graphql-tag';
import { graphql, type GraphQLResponseResolver, type RequestHandlerOptions } from 'msw';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
type GraphQLClientRequestHeaders = RequestOptions['requestHeaders'];
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
};

/**
 * プロフィールなどを含むユーザーデータ。
 * Providerとはたまたま同じような構造をしている。
 */
export type Account = {
  __typename?: 'Account';
  avatarUrl?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /**
   * リッチエディタで入力されたもの。
   * facebook/lexicalを使用するので、json文字列が入っている。JSON.parseして使う。
   */
  profile?: Maybe<Scalars['String']['output']>;
};

export type AccountInput = {
  /**
   * 画像のバイナリをbase64でエンコードした文字列
   * 形式はpng,jpegのいずれか
   * サイズはデコードした状態で5MBまで
   * data-urlではなく、バイナリ本体を要求することに注意
   */
  base64EncodedAvatarImage?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  /** リッチエディタのデータ構造(json)を文字列にしたもの。 */
  profile?: InputMaybe<Scalars['String']['input']>;
};

/** createAccount, updateAccountの戻り値 */
export type AccountPayload = Account | UserError;

export type AddTrialBlockListItemInput = {
  emailDomain: Scalars['String']['input'];
  memo?: InputMaybe<Scalars['String']['input']>;
};

/** addTrialBlockListItemの戻り値 */
export type AddTrialBlockListItemPayload = TrialBlockListItem | UserError;

/**
 * 管理者用のテンプレートの中身。
 * templateMetaDataは一般ユーザーと同様に誰でもアクセスが可能だが、templateMainDataは管理者のみがフィールドにアクセスすることができる。
 */
export type AdminTemplateContent = {
  __typename?: 'AdminTemplateContent';
  /** テンプレート本体以外の情報 */
  templateContent: TemplateContent;
  /**
   * テンプレート本体の情報
   * :AdminTemplateContent/templateDownloadData :admin
   */
  templateDownloadData: TemplateDownloadData;
};

/** deleteTrialBlockListItemの戻り値 */
export type DeleteTrialBlockListItemPayload = TrialBlockListItem | UserError;

/** エラーの詳細。どの箇所でどんなエラーが出ているか。 */
export type ErrorField = {
  __typename?: 'ErrorField';
  message: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /**
   * emailを指定してブロックリストに追加する
   * 既にリストに同じemailの項目が存在している場合はUserErrorを返す
   * 成功した場合は、追加されたTrialBlockListItemを返す
   * role: admin
   */
  addTrialBlockListItem: AddTrialBlockListItemPayload;
  /**
   * ユーザーページデモ参加者アプリからメールアドレスを含んだレコードを削除する
   * role :authenticated
   */
  cancelDemo?: Maybe<UserError>;
  /**
   * テンプレートの作成。templateとdraftTemplateが同時に作成される
   * role: admin
   */
  createTemplate: TemplatePayload;
  /**
   * タグを作成する
   * role: admin
   */
  createTemplateTag: TemplateTagPayload;
  /**
   * 削除については都度リクエストする必要はないが、アップロードと同じ挙動をした方がわかりやすいと思われるため、updateDraftTemplateとは別にする。
   * role: admin
   */
  deleteScreenshot?: Maybe<UserError>;
  /**
   * テンプレートの削除。templateとdraftTemplate,publishedTemplateが同時に削除される。
   * role: admin
   */
  deleteTemplate?: Maybe<UserError>;
  /**
   * タグを削除する
   * role: admin
   */
  deleteTemplateTag?: Maybe<UserError>;
  /**
   * blockListの項目を削除する
   * 変更対象が存在しない場合はUserErrorを返し、成功した場合は何も返さない
   * role: admin
   */
  deleteTrialBlockListItem?: Maybe<UserError>;
  /**
   * 下書きテンプレートを公開テンプレートに反映し、template.statusをpublicにする。draft_template_contentをpublished_template_contentにコピーする。
   * role: admin
   */
  publishTemplate: TemplatePayload;
  /**
   * アカウント情報の作成/更新。作成時はログインemailからfirebase idに変換して保存。
   * role :authenticated
   */
  saveAccount: AccountPayload;
  /**
   * トヨクモ限定公開。template.statusをtoyokumoにする
   * role: admin
   */
  publishToyokumoTemplate: TemplatePayload;
  /**
   * 公開中のテンプレートを非公開にする。template.statusをprivateにする
   * role: admin
   */
  unpublishedTemplate: TemplatePayload;
  /**
   * 下書きテンプレートを保存。draft_template_contentを更新する
   * role: admin
   */
  updateDraftTemplate: TemplatePayload;
  /**
   * タグを更新する
   * role: admin
   */
  updateTemplateTag: TemplateTagPayload;
  /**
   * blockListの項目の編集をする
   * 変更先が既に他の項目で指定されているemailDomainである場合はUserErrorを返す
   * 成功した場合は、更新された後のTrialBlockListを返す
   * role: admin
   */
  updateTrialBlockListItem: UpdateTrialBlockListItemPayload;
  /**
   * スクリーンショットに関して、順序の変更やサムネイルの選択については下書き保存ボタンで変更するが、1度に大量に画像を送信したくないのでアップロードについてはupdateDraftTemplateとは別で即座に下書きに反映させる。
   * このとき、ユーザーは編集中の状態であるためフロントでのキャッシュの管理はinvalidateして再フェッチするのではなく、setQueryDataなどで直接キャッシュやhook formを書き換える方針でいく。
   * 画像はbase64で送信するようにする。
   * role: admin
   */
  uploadScreenshot: TemplateScreenshotPayload;
};

export type MutationAddTrialBlockListItemArgs = {
  input: AddTrialBlockListItemInput;
};

export type MutationCreateTemplateArgs = {
  input: TemplateContentInput;
};

export type MutationCreateTemplateTagArgs = {
  input: TemplateTagInput;
};

export type MutationDeleteScreenshotArgs = {
  screenshotId: Scalars['String']['input'];
};

export type MutationDeleteTemplateArgs = {
  templateId: Scalars['String']['input'];
};

export type MutationDeleteTemplateTagArgs = {
  tagId: Scalars['String']['input'];
};

export type MutationDeleteTrialBlockListItemArgs = {
  id: Scalars['ID']['input'];
};

export type MutationPublishTemplateArgs = {
  templateId: Scalars['String']['input'];
};

export type MutationSaveAccountArgs = {
  input?: InputMaybe<AccountInput>;
};

export type MutationPublishToyokumoTemplateArgs = {
  templateId: Scalars['String']['input'];
};

export type MutationUnpublishedTemplateArgs = {
  templateId: Scalars['String']['input'];
};

export type MutationUpdateDraftTemplateArgs = {
  draftTemplateId: Scalars['String']['input'];
  input: TemplateContentInput;
};

export type MutationUpdateTemplateTagArgs = {
  input: TemplateTagInput;
  tagId: Scalars['String']['input'];
};

export type MutationUpdateTrialBlockListItemArgs = {
  input: UpdateTrialBlockListItemInput;
};

export type MutationUploadScreenshotArgs = {
  base64EncodedScreenshotImage: Scalars['String']['input'];
};

/** 全kintone連携製品共通の型定義 */
export enum Plan {
  Light = 'LIGHT',
  Premium = 'PREMIUM',
  Professional = 'PROFESSIONAL',
  Standard = 'STANDARD',
  Trial = 'TRIAL',
}

/** フォーム/ビューなどの提供元情報 */
export type Provider = {
  __typename?: 'Provider';
  avatarUrl?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /**
   * リッチエディタで入力されたもの。
   * facebook/lexicalを使用するのでjson文字列が入っている。JSON.parseして使う。
   */
  profile?: Maybe<Scalars['String']['output']>;
  /** firebase user id。フォーム一覧と付き合わせる用。 */
  providerId: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  /**
   * アカウント情報の取得。存在していなければnullを返す。
   * nullの場合は保存時にcreateAccountで作成を行う。
   * role: authenticated
   */
  account?: Maybe<Account>;
  /**
   * 公開されているテンプレートをダウンロードする。
   * ダウンロードを行うユーザーが要求プランを満たしているのか。テンプレートは公開状態なのか、などのバリデーションを行う。
   * template_download_historyテーブルに誰がどのテンプレートをダウンロードしたのかを記録する。
   * テンプレートコードの場合はそのコードの文字列を返し、zipの場合は保存場所へのsignedUrlを返す
   * role: authenticated
   * TODO: signedUrlで返すことを考慮する
   */
  downloadTemplate?: Maybe<TemplateDownloadDataPayload>;
  /**
   * 提供元一のプロフィール設定を取得する。
   * fb, kv 側で得た providerId と付き合わせるために存在しないidが混じっていてもエラーにはならない。
   * 例: ids: ["ID-A", "存在しないID", "ID-B", "nameが空のID"] の結果は [{providerId: "ID-A"}, {providerId: "ID-B"}] になる
   * role: authenticated
   */
  providers: Array<Provider>;
  /**
   * タグの一覧を取得する
   * role: authenticated
   */
  tags: Array<TemplateTag>;
  /**
   * ギャラリー用テンプレートの単体の情報を取得する
   * 将来的にroleは anonymous にするかもだが、しばらくは authenticated
   * role: authenticated
   */
  template?: Maybe<Template>;
  /**
   * テンプレートを一覧取得する。
   * role: authenticated
   */
  templates: Array<Template>;
  /**
   * 登録されているすべてのブロックリストの項目を取得する
   * role: admin
   */
  trialBlockList: Array<TrialBlockListItem>;
  /**
   * タグの名前の入力欄について、フォーカスを外した時にタグの名前が被っていないかをチェックする
   * role: admin
   */
  validateTagName: Scalars['Boolean']['output'];
  /**
   * テンプレート名の入力欄のフォーカスを外した時にテンプレートの名前が被っていないかをチェックする。publishedTemplateに対して検索をかける。
   * role: admin
   */
  validateTemplateName: Scalars['Boolean']['output'];
};

export type QueryDownloadTemplateArgs = {
  templateId: Scalars['String']['input'];
};

export type QueryProvidersArgs = {
  ids: Array<Scalars['String']['input']>;
};

export type QueryTemplateArgs = {
  templateId: Scalars['String']['input'];
};

export type QueryValidateTagNameArgs = {
  name: Scalars['String']['input'];
};

export type QueryValidateTemplateNameArgs = {
  name: Scalars['String']['input'];
};

/** 各kintone連携サービス */
export type Service = {
  __typename?: 'Service';
  id: Scalars['Int']['output'];
  name: ServiceName;
};

/** 各サービスの名前。serviceマスタテーブルと合わせる。 */
export enum ServiceName {
  DataCollect = 'DATA_COLLECT',
  FormBridge = 'FORM_BRIDGE',
  KBackup = 'K_BACKUP',
  KMailer = 'K_MAILER',
  KViewer = 'K_VIEWER',
  PrintCreator = 'PRINT_CREATOR',
}

/** 下書きテンプレートと公開テンプレートの両方を管理する親情報 */
export type Template = {
  __typename?: 'Template';
  downloadCount: Scalars['Int']['output'];
  draftTemplateContent: AdminTemplateContent;
  firstPublishedAt?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  publishedTemplateContent?: Maybe<TemplateContent>;
  /** テンプレートの公開状態 */
  status: TemplateStatus;
};

export type TemplateContent = {
  __typename?: 'TemplateContent';
  /** テンプレートの作成日時 */
  createdAt: Scalars['String']['output'];
  /** テンプレートのデモへのURL */
  demoUrl: Scalars['String']['output'];
  id: Scalars['String']['output'];
  /** テンプレートの機能をフルで利用するために連携する必要があるサービス */
  linkedServices: Array<Service>;
  /** テンプレートをダウンロードするために契約する必要がある最小コース */
  minimumAvailablePlan: Plan;
  /** テンプレートの名前 */
  name: Scalars['String']['output'];
  /** 概要 */
  overview: Scalars['String']['output'];
  /** テンプレートのスクリーンショット */
  screenshots: Array<TemplateScreenshot>;
  /** タグ */
  tags: Array<TemplateTag>;
  /** テンプレートをダウンロードするために契約する必要があるサービス */
  targetService: Service;
  /** タイトル */
  title: Scalars['String']['output'];
  /** テンプレートの更新日時 */
  updatedAt: Scalars['String']['output'];
  /** 使い方 */
  usageInstruction: Scalars['String']['output'];
};

/** テンプレートの作成や編集のmutationで使用する入力パラメータ */
export type TemplateContentInput = {
  /** ファイルはbase64でエンコードして送信する */
  base64EncodedZipTemplate?: InputMaybe<Scalars['String']['input']>;
  codeTemplate?: InputMaybe<Scalars['String']['input']>;
  demoUrl: Scalars['String']['input'];
  linkedServiceNames: Array<ServiceName>;
  minimumAvailablePlan: Plan;
  name: Scalars['String']['input'];
  overview: Scalars['String']['input'];
  tagIds: Array<Scalars['String']['input']>;
  /** 他のテーブルとの関連の情報はidのみを送信して差分を反映させる */
  targetServiceName: ServiceName;
  templateType: TemplateType;
  title: Scalars['String']['input'];
  usageInstruction: Scalars['String']['input'];
};

/** テンプレートの中身へのmutationに対する戻り値 */
export type TemplateContentPayload = TemplateContent | UserError;

/**
 * テンプレートのデータ。
 * adminのみ参照可能なフィールドを定義する際に、フィールドごとにresolverを作りたくないので意味ある単位で必要なデータをまとめている。
 * :AdminTemplateContent/templateDownloadData :admin
 */
export type TemplateDownloadData = {
  __typename?: 'TemplateDownloadData';
  /** コードテンプレートの内容 */
  codeTemplate?: Maybe<Scalars['String']['output']>;
  /** zipファイルのテンプレート or 文字列コードのテンプレート */
  templateType: TemplateType;
  /** zipファイルのテンプレートのファイル名 */
  zipTemplateFileName?: Maybe<Scalars['String']['output']>;
};

export type TemplateDownloadDataPayload = TemplateDownloadData | UserError;

/** テンプレート全体に関わるmutationに対する戻り値 */
export type TemplatePayload = Template | UserError;

/** テンプレートのスクリーンショット。テンプレートがもつスクリーンショットのうち、必ず1つだけサムネイルとして選択されている。 */
export type TemplateScreenshot = {
  __typename?: 'TemplateScreenshot';
  id: Scalars['String']['output'];
  /** imgタグのalt属性 */
  imageAltText: Scalars['String']['output'];
  /** 画像の保存先(s3)へのURL */
  imageUrl: Scalars['String']['output'];
  /** サムネイル画像かどうか */
  isThumbnail: Scalars['Boolean']['output'];
  /** 画像の並び順 */
  sortOrder: Scalars['Int']['output'];
};

/** テンプレートのスクリーンショットへのmutationに対する戻り値 */
export type TemplateScreenshotPayload = TemplateScreenshot | UserError;

export type TemplateSearchInput = {
  searchWord: Scalars['String']['input'];
  serviceId?: InputMaybe<Scalars['Int']['input']>;
  tagIds?: InputMaybe<Scalars['String']['input']>;
};

/** テンプレートの公開状態 */
export enum TemplateStatus {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
  /** トヨクモ限定公開 */
  Toyokumo = 'TOYOKUMO',
}

/** テンプレートに設定するためのタグ */
export type TemplateTag = {
  __typename?: 'TemplateTag';
  /** そのタグがついているテンプレートがダウンロードされた合計数 */
  downloadCount: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  /** タグに関係するサービス */
  relatedServices: Array<Service>;
};

/** タグの作成や編集のmutationで使用する入力パラメータ */
export type TemplateTagInput = {
  name: Scalars['String']['input'];
  /** 他のテーブルとの関連の情報はidのみを送信して差分を反映させる */
  relatedServiceNames: Array<ServiceName>;
};

/** テンプレートのタグへのmutationに対する戻り値 */
export type TemplateTagPayload = TemplateTag | UserError;

/** 登録するテンプレートの種類 */
export enum TemplateType {
  /** ソースコードを文字列で貼り付けるテンプレート */
  SourceCode = 'SOURCE_CODE',
  /** .zipファイルのテンプレート */
  Zip = 'ZIP',
}

export type TrialBlockListItem = {
  __typename?: 'TrialBlockListItem';
  /** データが作成された日時を表すISO文字列 */
  blockedAt: Scalars['String']['output'];
  /**
   * ブロックするemailアドレスのドメインを指す
   * 例） "evil.com" : evil.comのドメインを持つ全てのメールアドレスによる環境作成をブロックする
   */
  emailDomain: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  memo?: Maybe<Scalars['String']['output']>;
  /** データが最後に編集された日時を表すISO文字列 */
  updatedAt: Scalars['String']['output'];
};

export type UpdateTrialBlockListItemInput = {
  emailDomain: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  memo?: InputMaybe<Scalars['String']['input']>;
};

/** updateTrialBlockListItemの戻り値 */
export type UpdateTrialBlockListItemPayload = TrialBlockListItem | UserError;

/**
 * ユーザーの入力によって生じたエラーを示す
 * 原則としてユーザーの入力を修正することでリクエストは成功する可能性がある
 * バリデーションエラーなどドメインロジックに関係するエラー、復帰可能なエラー、などを伴う処理はこれの union を返す
 * mutaion では基本的に使うべき
 * query では引数の検証などが必要な時は使うべき。引数が無い場合は使わないこともある
 */
export type UserError = {
  __typename?: 'UserError';
  /**
   * エラーの種別。主にプログラムで処理を分ける用
   * TODO: 決まってきたら Enum にする
   */
  code: Scalars['String']['output'];
  /**
   * エラーの詳細の一覧。
   * 例： [{ name: "email", message: "emailの仕様に合ってません" }, { name: token, message: "期限切れです" }]
   */
  fields: Array<ErrorField>;
  /**
   * ユーザーに見せるためのエラーメッセージ
   * 例: 入力されたEmailが正しくありません。
   */
  message: Scalars['String']['output'];
};

export type FullTrialBlockListItemFragment = {
  __typename?: 'TrialBlockListItem';
  id: string;
  emailDomain: string;
  blockedAt: string;
  updatedAt: string;
  memo?: string | null;
};

export type FullUserErrorFragment = {
  __typename?: 'UserError';
  message: string;
  code: string;
  fields: Array<{ __typename?: 'ErrorField'; name: string; message: string }>;
};

export type AccountQueryVariables = Exact<{ [key: string]: never }>;

export type AccountQuery = {
  __typename?: 'Query';
  account?: { __typename?: 'Account'; name?: string | null; profile?: string | null; avatarUrl?: string | null } | null;
};

export type AddTrialBlockListItemMutationVariables = Exact<{
  input: AddTrialBlockListItemInput;
}>;

export type AddTrialBlockListItemMutation = {
  __typename?: 'Mutation';
  addTrialBlockListItem:
    | {
        __typename: 'TrialBlockListItem';
        id: string;
        emailDomain: string;
        blockedAt: string;
        updatedAt: string;
        memo?: string | null;
      }
    | {
        __typename: 'UserError';
        message: string;
        code: string;
        fields: Array<{ __typename?: 'ErrorField'; name: string; message: string }>;
      };
};

export type DeleteTrialBlockListItemMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteTrialBlockListItemMutation = {
  __typename?: 'Mutation';
  deleteTrialBlockListItem?: {
    __typename?: 'UserError';
    message: string;
    code: string;
    fields: Array<{ __typename?: 'ErrorField'; name: string; message: string }>;
  } | null;
};

export type TrialBlockListQueryVariables = Exact<{ [key: string]: never }>;

export type TrialBlockListQuery = {
  __typename?: 'Query';
  trialBlockList: Array<{
    __typename?: 'TrialBlockListItem';
    id: string;
    emailDomain: string;
    blockedAt: string;
    updatedAt: string;
    memo?: string | null;
  }>;
};

export type UpdateTrialBlockListItemMutationVariables = Exact<{
  input: UpdateTrialBlockListItemInput;
}>;

export type UpdateTrialBlockListItemMutation = {
  __typename?: 'Mutation';
  updateTrialBlockListItem:
    | {
        __typename: 'TrialBlockListItem';
        id: string;
        emailDomain: string;
        blockedAt: string;
        updatedAt: string;
        memo?: string | null;
      }
    | {
        __typename: 'UserError';
        message: string;
        code: string;
        fields: Array<{ __typename?: 'ErrorField'; name: string; message: string }>;
      };
};

export type CancelDemoMutationVariables = Exact<{ [key: string]: never }>;

export type CancelDemoMutation = {
  __typename?: 'Mutation';
  cancelDemo?: { __typename: 'UserError'; code: string; message: string } | null;
};

export type GetProviderQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetProviderQuery = {
  __typename?: 'Query';
  providers: Array<{
    __typename?: 'Provider';
    providerId: string;
    name?: string | null;
    avatarUrl?: string | null;
    profile?: string | null;
  }>;
};

export type GetProvidersQueryVariables = Exact<{
  ids: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type GetProvidersQuery = {
  __typename?: 'Query';
  providers: Array<{ __typename?: 'Provider'; providerId: string; name?: string | null; avatarUrl?: string | null }>;
};

export type SaveAccountMutationVariables = Exact<{
  input: AccountInput;
}>;

export type SaveAccountMutation = {
  __typename?: 'Mutation';
  saveAccount:
    | { __typename: 'Account'; avatarUrl?: string | null; name?: string | null; profile?: string | null }
    | {
        __typename: 'UserError';
        code: string;
        message: string;
        fields: Array<{ __typename?: 'ErrorField'; name: string; message: string }>;
      };
};

export type AdminTemplateTagsQueryVariables = Exact<{ [key: string]: never }>;

export type AdminTemplateTagsQuery = {
  __typename?: 'Query';
  tags: Array<{
    __typename?: 'TemplateTag';
    id: string;
    name: string;
    downloadCount: number;
    relatedServices: Array<{ __typename?: 'Service'; id: number; name: ServiceName }>;
  }>;
};

export type CreateTemplateTagMutationVariables = Exact<{
  input: TemplateTagInput;
}>;

export type CreateTemplateTagMutation = {
  __typename?: 'Mutation';
  createTemplateTag:
    | { __typename: 'TemplateTag'; id: string }
    | {
        __typename: 'UserError';
        message: string;
        code: string;
        fields: Array<{ __typename?: 'ErrorField'; name: string; message: string }>;
      };
};

export type DeleteTemplateTagMutationVariables = Exact<{
  tagId: Scalars['String']['input'];
}>;

export type DeleteTemplateTagMutation = {
  __typename?: 'Mutation';
  deleteTemplateTag?: {
    __typename: 'UserError';
    message: string;
    code: string;
    fields: Array<{ __typename?: 'ErrorField'; name: string; message: string }>;
  } | null;
};

export type UpdateTemplateTagMutationVariables = Exact<{
  tagId: Scalars['String']['input'];
  input: TemplateTagInput;
}>;

export type UpdateTemplateTagMutation = {
  __typename?: 'Mutation';
  updateTemplateTag:
    | { __typename: 'TemplateTag'; id: string }
    | {
        __typename: 'UserError';
        message: string;
        code: string;
        fields: Array<{ __typename?: 'ErrorField'; name: string; message: string }>;
      };
};

export const FullTrialBlockListItemFragmentDoc = gql`
  fragment FullTrialBlockListItem on TrialBlockListItem {
    id
    emailDomain
    blockedAt
    updatedAt
    memo
  }
`;
export const FullUserErrorFragmentDoc = gql`
  fragment FullUserError on UserError {
    message
    code
    fields {
      name
      message
    }
  }
`;
export const AccountDocument = gql`
  query account {
    account {
      name
      profile
      avatarUrl
    }
  }
`;
export const AddTrialBlockListItemDocument = gql`
  mutation addTrialBlockListItem($input: AddTrialBlockListItemInput!) {
    addTrialBlockListItem(input: $input) {
      __typename
      ... on TrialBlockListItem {
        ...FullTrialBlockListItem
      }
      ... on UserError {
        ...FullUserError
      }
    }
  }
  ${FullTrialBlockListItemFragmentDoc}
  ${FullUserErrorFragmentDoc}
`;
export const DeleteTrialBlockListItemDocument = gql`
  mutation deleteTrialBlockListItem($id: ID!) {
    deleteTrialBlockListItem(id: $id) {
      ...FullUserError
    }
  }
  ${FullUserErrorFragmentDoc}
`;
export const TrialBlockListDocument = gql`
  query trialBlockList {
    trialBlockList {
      ...FullTrialBlockListItem
    }
  }
  ${FullTrialBlockListItemFragmentDoc}
`;
export const UpdateTrialBlockListItemDocument = gql`
  mutation updateTrialBlockListItem($input: UpdateTrialBlockListItemInput!) {
    updateTrialBlockListItem(input: $input) {
      __typename
      ... on TrialBlockListItem {
        ...FullTrialBlockListItem
      }
      ... on UserError {
        ...FullUserError
      }
    }
  }
  ${FullTrialBlockListItemFragmentDoc}
  ${FullUserErrorFragmentDoc}
`;
export const CancelDemoDocument = gql`
  mutation cancelDemo {
    cancelDemo {
      __typename
      ... on UserError {
        code
        message
      }
    }
  }
`;
export const GetProviderDocument = gql`
  query getProvider($id: String!) {
    providers(ids: [$id]) {
      providerId
      name
      avatarUrl
      profile
    }
  }
`;
export const GetProvidersDocument = gql`
  query getProviders($ids: [String!]!) {
    providers(ids: $ids) {
      providerId
      name
      avatarUrl
    }
  }
`;
export const SaveAccountDocument = gql`
  mutation saveAccount($input: AccountInput!) {
    saveAccount(input: $input) {
      __typename
      ... on Account {
        avatarUrl
        name
        profile
      }
      ... on UserError {
        code
        message
        fields {
          name
          message
        }
      }
    }
  }
`;
export const AdminTemplateTagsDocument = gql`
  query adminTemplateTags {
    tags {
      id
      name
      relatedServices {
        id
        name
      }
      downloadCount
    }
  }
`;
export const CreateTemplateTagDocument = gql`
  mutation createTemplateTag($input: TemplateTagInput!) {
    createTemplateTag(input: $input) {
      __typename
      ... on TemplateTag {
        id
      }
      ... on UserError {
        ...FullUserError
      }
    }
  }
  ${FullUserErrorFragmentDoc}
`;
export const DeleteTemplateTagDocument = gql`
  mutation deleteTemplateTag($tagId: String!) {
    deleteTemplateTag(tagId: $tagId) {
      __typename
      ... on UserError {
        ...FullUserError
      }
    }
  }
  ${FullUserErrorFragmentDoc}
`;
export const UpdateTemplateTagDocument = gql`
  mutation updateTemplateTag($tagId: String!, $input: TemplateTagInput!) {
    updateTemplateTag(tagId: $tagId, input: $input) {
      __typename
      ... on TemplateTag {
        id
      }
      ... on UserError {
        ...FullUserError
      }
    }
  }
  ${FullUserErrorFragmentDoc}
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string,
  variables?: any,
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType, _variables) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    account(variables?: AccountQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<AccountQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<AccountQuery>(AccountDocument, variables, { ...requestHeaders, ...wrappedRequestHeaders }),
        'account',
        'query',
        variables,
      );
    },
    addTrialBlockListItem(
      variables: AddTrialBlockListItemMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<AddTrialBlockListItemMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<AddTrialBlockListItemMutation>(AddTrialBlockListItemDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'addTrialBlockListItem',
        'mutation',
        variables,
      );
    },
    deleteTrialBlockListItem(
      variables: DeleteTrialBlockListItemMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<DeleteTrialBlockListItemMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<DeleteTrialBlockListItemMutation>(DeleteTrialBlockListItemDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'deleteTrialBlockListItem',
        'mutation',
        variables,
      );
    },
    trialBlockList(
      variables?: TrialBlockListQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<TrialBlockListQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<TrialBlockListQuery>(TrialBlockListDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'trialBlockList',
        'query',
        variables,
      );
    },
    updateTrialBlockListItem(
      variables: UpdateTrialBlockListItemMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UpdateTrialBlockListItemMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateTrialBlockListItemMutation>(UpdateTrialBlockListItemDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'updateTrialBlockListItem',
        'mutation',
        variables,
      );
    },
    cancelDemo(
      variables?: CancelDemoMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<CancelDemoMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CancelDemoMutation>(CancelDemoDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'cancelDemo',
        'mutation',
        variables,
      );
    },
    getProvider(
      variables: GetProviderQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetProviderQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetProviderQuery>(GetProviderDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'getProvider',
        'query',
        variables,
      );
    },
    getProviders(
      variables: GetProvidersQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetProvidersQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetProvidersQuery>(GetProvidersDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'getProviders',
        'query',
        variables,
      );
    },
    saveAccount(
      variables: SaveAccountMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<SaveAccountMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<SaveAccountMutation>(SaveAccountDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'saveAccount',
        'mutation',
        variables,
      );
    },
    adminTemplateTags(
      variables?: AdminTemplateTagsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<AdminTemplateTagsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<AdminTemplateTagsQuery>(AdminTemplateTagsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'adminTemplateTags',
        'query',
        variables,
      );
    },
    createTemplateTag(
      variables: CreateTemplateTagMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<CreateTemplateTagMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateTemplateTagMutation>(CreateTemplateTagDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'createTemplateTag',
        'mutation',
        variables,
      );
    },
    deleteTemplateTag(
      variables: DeleteTemplateTagMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<DeleteTemplateTagMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<DeleteTemplateTagMutation>(DeleteTemplateTagDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'deleteTemplateTag',
        'mutation',
        variables,
      );
    },
    updateTemplateTag(
      variables: UpdateTemplateTagMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UpdateTemplateTagMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateTemplateTagMutation>(UpdateTemplateTagDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'updateTemplateTag',
        'mutation',
        variables,
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockAccountQuery(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { account }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockAccountQuery = (
  resolver: GraphQLResponseResolver<AccountQuery, AccountQueryVariables>,
  options?: RequestHandlerOptions,
) => graphql.query<AccountQuery, AccountQueryVariables>('account', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockAddTrialBlockListItemMutation(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { addTrialBlockListItem }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockAddTrialBlockListItemMutation = (
  resolver: GraphQLResponseResolver<AddTrialBlockListItemMutation, AddTrialBlockListItemMutationVariables>,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<AddTrialBlockListItemMutation, AddTrialBlockListItemMutationVariables>(
    'addTrialBlockListItem',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockDeleteTrialBlockListItemMutation(
 *   ({ query, variables }) => {
 *     const { id } = variables;
 *     return HttpResponse.json({
 *       data: { deleteTrialBlockListItem }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockDeleteTrialBlockListItemMutation = (
  resolver: GraphQLResponseResolver<DeleteTrialBlockListItemMutation, DeleteTrialBlockListItemMutationVariables>,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<DeleteTrialBlockListItemMutation, DeleteTrialBlockListItemMutationVariables>(
    'deleteTrialBlockListItem',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockTrialBlockListQuery(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { trialBlockList }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockTrialBlockListQuery = (
  resolver: GraphQLResponseResolver<TrialBlockListQuery, TrialBlockListQueryVariables>,
  options?: RequestHandlerOptions,
) => graphql.query<TrialBlockListQuery, TrialBlockListQueryVariables>('trialBlockList', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateTrialBlockListItemMutation(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { updateTrialBlockListItem }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockUpdateTrialBlockListItemMutation = (
  resolver: GraphQLResponseResolver<UpdateTrialBlockListItemMutation, UpdateTrialBlockListItemMutationVariables>,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<UpdateTrialBlockListItemMutation, UpdateTrialBlockListItemMutationVariables>(
    'updateTrialBlockListItem',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCancelDemoMutation(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { cancelDemo }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockCancelDemoMutation = (
  resolver: GraphQLResponseResolver<CancelDemoMutation, CancelDemoMutationVariables>,
  options?: RequestHandlerOptions,
) => graphql.mutation<CancelDemoMutation, CancelDemoMutationVariables>('cancelDemo', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetProviderQuery(
 *   ({ query, variables }) => {
 *     const { id } = variables;
 *     return HttpResponse.json({
 *       data: { providers }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetProviderQuery = (
  resolver: GraphQLResponseResolver<GetProviderQuery, GetProviderQueryVariables>,
  options?: RequestHandlerOptions,
) => graphql.query<GetProviderQuery, GetProviderQueryVariables>('getProvider', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetProvidersQuery(
 *   ({ query, variables }) => {
 *     const { ids } = variables;
 *     return HttpResponse.json({
 *       data: { providers }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetProvidersQuery = (
  resolver: GraphQLResponseResolver<GetProvidersQuery, GetProvidersQueryVariables>,
  options?: RequestHandlerOptions,
) => graphql.query<GetProvidersQuery, GetProvidersQueryVariables>('getProviders', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockSaveAccountMutation(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { saveAccount }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockSaveAccountMutation = (
  resolver: GraphQLResponseResolver<SaveAccountMutation, SaveAccountMutationVariables>,
  options?: RequestHandlerOptions,
) => graphql.mutation<SaveAccountMutation, SaveAccountMutationVariables>('saveAccount', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockAdminTemplateTagsQuery(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { tags }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockAdminTemplateTagsQuery = (
  resolver: GraphQLResponseResolver<AdminTemplateTagsQuery, AdminTemplateTagsQueryVariables>,
  options?: RequestHandlerOptions,
) => graphql.query<AdminTemplateTagsQuery, AdminTemplateTagsQueryVariables>('adminTemplateTags', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreateTemplateTagMutation(
 *   ({ query, variables }) => {
 *     const { input } = variables;
 *     return HttpResponse.json({
 *       data: { createTemplateTag }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockCreateTemplateTagMutation = (
  resolver: GraphQLResponseResolver<CreateTemplateTagMutation, CreateTemplateTagMutationVariables>,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<CreateTemplateTagMutation, CreateTemplateTagMutationVariables>(
    'createTemplateTag',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockDeleteTemplateTagMutation(
 *   ({ query, variables }) => {
 *     const { tagId } = variables;
 *     return HttpResponse.json({
 *       data: { deleteTemplateTag }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockDeleteTemplateTagMutation = (
  resolver: GraphQLResponseResolver<DeleteTemplateTagMutation, DeleteTemplateTagMutationVariables>,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<DeleteTemplateTagMutation, DeleteTemplateTagMutationVariables>(
    'deleteTemplateTag',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateTemplateTagMutation(
 *   ({ query, variables }) => {
 *     const { tagId, input } = variables;
 *     return HttpResponse.json({
 *       data: { updateTemplateTag }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockUpdateTemplateTagMutation = (
  resolver: GraphQLResponseResolver<UpdateTemplateTagMutation, UpdateTemplateTagMutationVariables>,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<UpdateTemplateTagMutation, UpdateTemplateTagMutationVariables>(
    'updateTemplateTag',
    resolver,
    options,
  );
