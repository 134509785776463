import { GraphQLClient, RequestOptions } from 'graphql-request';
import gql from 'graphql-tag';
import { graphql, type GraphQLResponseResolver, type RequestHandlerOptions } from 'msw';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
type GraphQLClientRequestHeaders = RequestOptions['requestHeaders'];
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
};

/** アカウント */
export type Account = AccountInterface & {
  __typename?: 'Account';
  company: Scalars['String']['output'];
  /** 小文字化されたメールアドレス */
  email: Scalars['String']['output'];
  /** kintone接続情報。契約したばかりの状態だとkintone接続情報を持っていない可能性がある。 */
  kintoneSetting?: Maybe<KintoneSetting>;
  name: Scalars['String']['output'];
  plan: Plan;
  /** DB内の生のid。adminとの連携など生のidを参照したいときに使用する。refetch用には使えない。 */
  rawId: Scalars['String']['output'];
  /** ユーザーの登録日時を示すISO8601文字列。製品によってはcreatedAtだが、registeredAtに統一する。 */
  registeredAt: Scalars['String']['output'];
  subAccount?: Maybe<SubAccount>;
  /** planがお試し中の時に入るお試し終了期日を示すISO8601文字列 */
  trialExpiresAt?: Maybe<Scalars['String']['output']>;
};

/**
 * アカウントの共通型。
 * Toyokukumo kintoneAppアカウントでは複数製品のAccountを扱うため共通の型を定義する。
 */
export type AccountInterface = {
  /** 製品によってはDBのスキーマ上だとnullableなので、nullを返さないように注意する */
  company: Scalars['String']['output'];
  /** 小文字化されたメールアドレス */
  email: Scalars['String']['output'];
  name: Scalars['String']['output'];
  plan: Plan;
  /** DB内の生のid。adminとの連携など生のidを参照したいときに使用する。refetch用には使えない。 */
  rawId: Scalars['String']['output'];
  /** ユーザーの登録日時を示すISO8601文字列。製品によってはcreatedAtだが、registeredAtに統一する。 */
  registeredAt: Scalars['String']['output'];
  /** planがお試し中の時に入るお試し終了期日を示すISO8601文字列 */
  trialExpiresAt?: Maybe<Scalars['String']['output']>;
};

/** サブ管理者を追加するリクエストをした時の戻り値 */
export type AddSubAccountPayload = SubAccount | UserError;

/**
 * FormBridgeしか知らないフロントエンドのルーティング定義を連携製品に教えるためのオブジェクト
 * name: ルーティング論理名
 * url: 物理パス。例: /recipe/781/show/form-mail-auth
 */
export type AppFrontUrl = {
  __typename?: 'AppFrontUrl';
  name: AppFrontUrlName;
  url: Scalars['String']['output'];
};

/** AppFrontUrlの種類。ルーティング論理名に対応している。 */
export enum AppFrontUrlName {
  EditFormKintoneappAuth = 'EDIT_FORM_KINTONEAPP_AUTH',
}

/** appFrontUrlsクエリの戻り値 */
export type AppFrontUrlsPayload = AppFrontUrlsResult | UserError;

/**
 * [AppFrontUrl!]! 型のフィールドを持つだけのオブジェクト
 * 直接配列を含むunion型を定義出来ないため回避策として使っている
 */
export type AppFrontUrlsResult = {
  __typename?: 'AppFrontUrlsResult';
  result: Array<AppFrontUrl>;
};

/** 編集完了したフォームの詳細設定。 */
export type DeployedForm = Node & {
  __typename?: 'DeployedForm';
  id: Scalars['ID']['output'];
  isPublic: Scalars['Boolean']['output'];
  kintoneAppAuthEnabled: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  /**
   * 公開・非公開に関わらず判別可能。フルパス。
   * URL自由に編集機能に注意する。日本語も入る可能性あり。urlエンコードはせず日本語そのまま返す。
   * 例: https://subdomain.form.kintoneapp.com/public/もちもち
   */
  publicUrl: Scalars['String']['output'];
};

/** エラーの詳細。どの箇所でどんなエラーが出ているか。 */
export type ErrorField = {
  __typename?: 'ErrorField';
  message: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

/** kintoneApp認証が有効なビューを閲覧可能なユーザーの情報 */
export type FormUser = {
  __typename?: 'FormUser';
  /** 小文字化されたメールアドレス */
  email: Scalars['String']['output'];
};

/** formUsersクエリの戻り値 */
export type FormUsersPayload = FormUsersResult | UserError;

/**
 * [FormUser!]! 型のフィールドを持つだけのオブジェクト
 * 直接配列を含むunion型を定義出来ないため回避策として使っている
 */
export type FormUsersResult = {
  __typename?: 'FormUsersResult';
  result: Array<FormUser>;
};

/** 招待中のステータス定義 */
export enum InvitationStatus {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
}

/** kintone接続情報 */
export type KintoneSetting = {
  __typename?: 'KintoneSetting';
  /** 契約kintoneのサブドメイン */
  subdomain: Scalars['String']['output'];
};

/** アカウントのうち、サブ管理者から参照されるときのアカウント定義 */
export type MainAccount = AccountInterface & {
  __typename?: 'MainAccount';
  company: Scalars['String']['output'];
  email: Scalars['String']['output'];
  name: Scalars['String']['output'];
  plan: Plan;
  /** DB内の生のid。adminとの連携など生のidを参照したいときに使用する。refetch用には使えない。 */
  rawId: Scalars['String']['output'];
  /** ユーザーの登録日時を示すISO8601文字列。製品によってはcreatedAtだが、registeredAtに統一する。 */
  registeredAt: Scalars['String']['output'];
  /** planがお試し中の時に入るお試し終了期日を示すISO8601文字列 */
  trialExpiresAt?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /**
   * 自分自身のアドレスで持っている環境にサブ管理者を追加する。
   * 自分自身のアドレスと同じメールアドレスでは追加できない。
   * 追加したサブ管理者は PENDING として追加され、account.kintoneapp.comで承諾することで認可される。
   * メインアカウントは関係がないことに注意
   * role :has-main-account
   */
  addSubAccount: AddSubAccountPayload;
  /**
   * 自分自身のアドレスがサブ管理者である環境に対して、招待の承諾を行う
   * サブ管理者のステータスをPENDINGからAPPROVEDに変更する。
   *
   * role :authenticated
   * mainAccountEmail: 自分自身を招待しているメインアカウントのemail
   */
  approveSubAccount: UpdateSubAccountPayload;
  /**
   * 自分自身のアドレスで持っている環境のサブ管理者を削除する。
   * 削除対象が存在しない場合にUserErrorが返る
   * メインアカウントは関係がないことに注意
   * role :has-main-account
   */
  deleteSubAccount?: Maybe<UserError>;
  /**
   * 自分自身のアドレスがサブ管理者である環境に対して、招待の辞退を行う
   * サブ管理者のステータスをPENDINGからREJECTEDに変更する。
   *
   * role :authenticated
   * mainAccountEmail: 自分自身を招待しているメインアカウントのemail
   */
  rejectSubAccount: UpdateSubAccountPayload;
  /**
   * 自分自身の所有している環境のアカウントの所有者をサブ管理者に変更する
   * 元の自分自身はサブ管理者になる
   * role :has-main-account
   */
  swapOwnerWithSubAccount?: Maybe<UserError>;
};

export type MutationAddSubAccountArgs = {
  email: Scalars['String']['input'];
};

export type MutationApproveSubAccountArgs = {
  mainAccountEmail: Scalars['String']['input'];
};

export type MutationRejectSubAccountArgs = {
  mainAccountEmail: Scalars['String']['input'];
};

export type Node = {
  id: Scalars['ID']['output'];
};

/** mainAccountNodeクエリの戻り値 */
export type NodePayload = NodeResult | UserError;

/**
 * Node型のフィールドを持つだけのオブジェクト
 * 直接インターフェースを含むunion型を定義出来ない為回避策として使っている
 */
export type NodeResult = {
  __typename?: 'NodeResult';
  result?: Maybe<Node>;
};

/** 全kintone連携製品共通の型定義 */
export enum Plan {
  Enterprise = 'ENTERPRISE',
  Light = 'LIGHT',
  Premium = 'PREMIUM',
  Professional = 'PROFESSIONAL',
  Standard = 'STANDARD',
  Trial = 'TRIAL',
}

/** フォームの提供元 */
export type Provider = {
  __typename?: 'Provider';
  forms: Array<PublicForm>;
  /** Toyokumo kintoneApp認証でログインしているemailに一致するfirebase userのuid */
  providerId: Scalars['String']['output'];
};

/** 公開されたフォーム設定。回答者が閲覧する用。 */
export type PublicForm = {
  __typename?: 'PublicForm';
  description?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  /**
   * フォームのURL。フルパス。
   * URL自由に編集機能に注意する。日本語も入る可能性あり。urlエンコードはせず日本語そのまま返す。
   * 例: https://subdomain.form.kintoneapp.com/public/もちもち
   */
  url: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  /**
   * 自分自身のアドレスに紐づくアカウント情報を取得する
   * アカウントの選択は関係ないことに注意が必要
   * メインアカウントは関係がないことに注意
   * role :authenticated
   */
  account?: Maybe<Account>;
  /**
   * IDに対応するGraphQLスキーマに関連するフロントエンドURL一覧を返す。製品間連携専用。
   * role: account
   */
  appFrontUrls: AppFrontUrlsPayload;
  /**
   * ビューのアクセス可能なユーザーをすべて取得
   * 大量取得を避けるため view と関連づかない形で個別に取得する
   * role: account
   */
  formUsers: FormUsersPayload;
  /**
   * kintoneApp認証付きフォームを取得
   * role: account
   */
  kintoneAppAuthEnabledForms: Array<DeployedForm>;
  /**
   * アカウント選択で選択したアカウント情報を取得する
   * role :account
   */
  mainAccount: Account;
  /**
   * 単にIDで取得するのではなく、必ずアカウント選択した環境のメインアカウントemailに紐づくものを取得する
   * role: account
   */
  mainAccountNode: NodePayload;
  /**
   * 自分自身のアドレスがサブ管理者であるリストを取得する
   * サブ管理者を登録したメインアカウントのプランは無料お試しかつ無料お試し期間内または契約済
   * 招待未承諾であるものだけ
   * メインアカウントは関係がないことに注意
   * role :authenticated
   */
  pendingSubAccounts: Array<SubAccount>;
  /**
   * 個別取得用。
   * kViewer側にもデータが存在する場合があるため、GIDを使わず firebase id を使う。クライアント側の利便性のため。
   * 存在しない場合やお試し期限切れの場合は null を返す。
   * role: authenticated
   */
  provider?: Maybe<Provider>;
  /**
   * 自分自身のメールアドレスで閲覧可能なフォーム一覧を持つmember取得する。
   * お試し期限切れのメンバーは含まない。
   * role: authenticated
   */
  providers: Array<Provider>;
  /**
   * 自分の公開中のToyokumo kintoneApp認証付きフォームを取得する。
   * ユーザーページのプロフィール設定で自分の持つフォーム一覧を確認する用。
   * role: has-main-account
   */
  providingForms: Array<PublicForm>;
  /**
   * 自分自身のアドレスがサブ管理者であるリストを取得する
   * サブ管理者を登録したメインアカウントのプランは無料お試しかつ無料お試し期間内または契約済
   * 招待承諾済であるものだけ
   * メインアカウントは関係がないことに注意
   * role :authenticated
   */
  subAccounts: Array<SubAccount>;
};

export type QueryAppFrontUrlsArgs = {
  id: Scalars['ID']['input'];
};

export type QueryFormUsersArgs = {
  formId: Scalars['ID']['input'];
};

export type QueryMainAccountNodeArgs = {
  id: Scalars['ID']['input'];
};

export type QueryProviderArgs = {
  id: Scalars['String']['input'];
};

/**
 * サブ管理者
 * アカウントは1つだけサブ管理者を設定できる
 */
export type SubAccount = {
  __typename?: 'SubAccount';
  email: Scalars['String']['output'];
  mainAccount: MainAccount;
  status: InvitationStatus;
};

/** 招待されているサブ管理者の承諾または辞退をした時の戻り値 */
export type UpdateSubAccountPayload = SubAccount | UserError;

/**
 * ユーザーの入力によって生じたエラーを示す
 * 原則としてユーザーの入力を修正することでリクエストは成功する可能性がある
 * バリデーションエラーなどドメインロジックに関係するエラー、復帰可能なエラー、などを伴う処理はこれの union を返す
 * mutaion では基本的に使うべき
 * query では引数の検証などが必要な時は使うべき。引数が無い場合は使わないこともある
 */
export type UserError = {
  __typename?: 'UserError';
  /**
   * エラーの種別。主にプログラムで処理を分ける用
   * TODO: 決まってきたら Enum にする
   */
  code: Scalars['String']['output'];
  /**
   * エラーの詳細の一覧。
   * 例： [{ name: "email", message: "emailの仕様に合ってません" }, { name: token, message: "期限切れです" }]
   */
  fields: Array<ErrorField>;
  /**
   * ユーザーに見せるためのエラーメッセージ
   * 例: 入力されたEmailが正しくありません。
   */
  message: Scalars['String']['output'];
};

export type AddSubAccountMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;

export type AddSubAccountMutation = {
  __typename?: 'Mutation';
  addSubAccount:
    | { __typename: 'SubAccount'; email: string }
    | {
        __typename: 'UserError';
        code: string;
        message: string;
        fields: Array<{ __typename?: 'ErrorField'; name: string; message: string }>;
      };
};

export type ApproveSubAccountMutationVariables = Exact<{
  mainAccountEmail: Scalars['String']['input'];
}>;

export type ApproveSubAccountMutation = {
  __typename?: 'Mutation';
  approveSubAccount: { __typename: 'SubAccount'; email: string } | { __typename: 'UserError'; message: string };
};

export type DeleteSubAccountMutationVariables = Exact<{ [key: string]: never }>;

export type DeleteSubAccountMutation = {
  __typename?: 'Mutation';
  deleteSubAccount?: { __typename: 'UserError'; code: string; message: string } | null;
};

export type MainAndSubAccountsQueryVariables = Exact<{ [key: string]: never }>;

export type MainAndSubAccountsQuery = {
  __typename?: 'Query';
  account?: {
    __typename?: 'Account';
    rawId: string;
    name: string;
    email: string;
    plan: Plan;
    company: string;
    trialExpiresAt?: string | null;
    registeredAt: string;
    subAccount?: { __typename?: 'SubAccount'; email: string; status: InvitationStatus } | null;
  } | null;
  subAccounts: Array<{
    __typename?: 'SubAccount';
    email: string;
    mainAccount: { __typename?: 'MainAccount'; email: string; plan: Plan; trialExpiresAt?: string | null };
  }>;
};

export type PendingSubAccountsQueryVariables = Exact<{ [key: string]: never }>;

export type PendingSubAccountsQuery = {
  __typename?: 'Query';
  pendingSubAccounts: Array<{
    __typename?: 'SubAccount';
    email: string;
    status: InvitationStatus;
    mainAccount: { __typename?: 'MainAccount'; email: string; plan: Plan; trialExpiresAt?: string | null };
  }>;
};

export type RejectSubAccountMutationVariables = Exact<{
  mainAccountEmail: Scalars['String']['input'];
}>;

export type RejectSubAccountMutation = {
  __typename?: 'Mutation';
  rejectSubAccount: { __typename: 'SubAccount'; email: string } | { __typename: 'UserError'; message: string };
};

export type SwapOwnerWithSubAccountMutationVariables = Exact<{ [key: string]: never }>;

export type SwapOwnerWithSubAccountMutation = {
  __typename?: 'Mutation';
  swapOwnerWithSubAccount?: {
    __typename: 'UserError';
    code: string;
    message: string;
    fields: Array<{ __typename?: 'ErrorField'; name: string; message: string }>;
  } | null;
};

export type GetProviderQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetProviderQuery = {
  __typename?: 'Query';
  provider?: {
    __typename?: 'Provider';
    forms: Array<{ __typename?: 'PublicForm'; url: string; title: string; description?: string | null }>;
  } | null;
};

export type GetProvidersQueryVariables = Exact<{ [key: string]: never }>;

export type GetProvidersQuery = {
  __typename?: 'Query';
  providers: Array<{
    __typename?: 'Provider';
    providerId: string;
    forms: Array<{ __typename?: 'PublicForm'; url: string; title: string; description?: string | null }>;
  }>;
};

export type GetProvidingFormsQueryVariables = Exact<{ [key: string]: never }>;

export type GetProvidingFormsQuery = {
  __typename?: 'Query';
  providingForms: Array<{ __typename?: 'PublicForm'; url: string; title: string; description?: string | null }>;
};

export const AddSubAccountDocument = gql`
  mutation addSubAccount($email: String!) {
    addSubAccount(email: $email) {
      __typename
      ... on SubAccount {
        email
      }
      ... on UserError {
        code
        message
        fields {
          name
          message
        }
      }
    }
  }
`;
export const ApproveSubAccountDocument = gql`
  mutation approveSubAccount($mainAccountEmail: String!) {
    approveSubAccount(mainAccountEmail: $mainAccountEmail) {
      __typename
      ... on SubAccount {
        email
      }
      ... on UserError {
        message
      }
    }
  }
`;
export const DeleteSubAccountDocument = gql`
  mutation deleteSubAccount {
    deleteSubAccount {
      __typename
      code
      message
    }
  }
`;
export const MainAndSubAccountsDocument = gql`
  query mainAndSubAccounts {
    account {
      rawId
      name
      email
      plan
      company
      trialExpiresAt
      registeredAt
      subAccount {
        email
        status
      }
    }
    subAccounts {
      email
      mainAccount {
        email
        plan
        trialExpiresAt
      }
    }
  }
`;
export const PendingSubAccountsDocument = gql`
  query pendingSubAccounts {
    pendingSubAccounts {
      email
      status
      mainAccount {
        email
        plan
        trialExpiresAt
      }
    }
  }
`;
export const RejectSubAccountDocument = gql`
  mutation rejectSubAccount($mainAccountEmail: String!) {
    rejectSubAccount(mainAccountEmail: $mainAccountEmail) {
      __typename
      ... on SubAccount {
        email
      }
      ... on UserError {
        message
      }
    }
  }
`;
export const SwapOwnerWithSubAccountDocument = gql`
  mutation swapOwnerWithSubAccount {
    swapOwnerWithSubAccount {
      __typename
      code
      message
      fields {
        name
        message
      }
    }
  }
`;
export const GetProviderDocument = gql`
  query getProvider($id: String!) {
    provider(id: $id) {
      forms {
        url
        title
        description
      }
    }
  }
`;
export const GetProvidersDocument = gql`
  query getProviders {
    providers {
      providerId
      forms {
        url
        title
        description
      }
    }
  }
`;
export const GetProvidingFormsDocument = gql`
  query getProvidingForms {
    providingForms {
      url
      title
      description
    }
  }
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string,
  variables?: any,
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType, _variables) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    addSubAccount(
      variables: AddSubAccountMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<AddSubAccountMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<AddSubAccountMutation>(AddSubAccountDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'addSubAccount',
        'mutation',
        variables,
      );
    },
    approveSubAccount(
      variables: ApproveSubAccountMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ApproveSubAccountMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ApproveSubAccountMutation>(ApproveSubAccountDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'approveSubAccount',
        'mutation',
        variables,
      );
    },
    deleteSubAccount(
      variables?: DeleteSubAccountMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<DeleteSubAccountMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<DeleteSubAccountMutation>(DeleteSubAccountDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'deleteSubAccount',
        'mutation',
        variables,
      );
    },
    mainAndSubAccounts(
      variables?: MainAndSubAccountsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<MainAndSubAccountsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<MainAndSubAccountsQuery>(MainAndSubAccountsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'mainAndSubAccounts',
        'query',
        variables,
      );
    },
    pendingSubAccounts(
      variables?: PendingSubAccountsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<PendingSubAccountsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<PendingSubAccountsQuery>(PendingSubAccountsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'pendingSubAccounts',
        'query',
        variables,
      );
    },
    rejectSubAccount(
      variables: RejectSubAccountMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<RejectSubAccountMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<RejectSubAccountMutation>(RejectSubAccountDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'rejectSubAccount',
        'mutation',
        variables,
      );
    },
    swapOwnerWithSubAccount(
      variables?: SwapOwnerWithSubAccountMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<SwapOwnerWithSubAccountMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<SwapOwnerWithSubAccountMutation>(SwapOwnerWithSubAccountDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'swapOwnerWithSubAccount',
        'mutation',
        variables,
      );
    },
    getProvider(
      variables: GetProviderQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetProviderQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetProviderQuery>(GetProviderDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'getProvider',
        'query',
        variables,
      );
    },
    getProviders(
      variables?: GetProvidersQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetProvidersQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetProvidersQuery>(GetProvidersDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'getProviders',
        'query',
        variables,
      );
    },
    getProvidingForms(
      variables?: GetProvidingFormsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetProvidingFormsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetProvidingFormsQuery>(GetProvidingFormsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'getProvidingForms',
        'query',
        variables,
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockAddSubAccountMutation(
 *   ({ query, variables }) => {
 *     const { email } = variables;
 *     return HttpResponse.json({
 *       data: { addSubAccount }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockAddSubAccountMutation = (
  resolver: GraphQLResponseResolver<AddSubAccountMutation, AddSubAccountMutationVariables>,
  options?: RequestHandlerOptions,
) => graphql.mutation<AddSubAccountMutation, AddSubAccountMutationVariables>('addSubAccount', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockApproveSubAccountMutation(
 *   ({ query, variables }) => {
 *     const { mainAccountEmail } = variables;
 *     return HttpResponse.json({
 *       data: { approveSubAccount }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockApproveSubAccountMutation = (
  resolver: GraphQLResponseResolver<ApproveSubAccountMutation, ApproveSubAccountMutationVariables>,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<ApproveSubAccountMutation, ApproveSubAccountMutationVariables>(
    'approveSubAccount',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockDeleteSubAccountMutation(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { deleteSubAccount }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockDeleteSubAccountMutation = (
  resolver: GraphQLResponseResolver<DeleteSubAccountMutation, DeleteSubAccountMutationVariables>,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<DeleteSubAccountMutation, DeleteSubAccountMutationVariables>('deleteSubAccount', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockMainAndSubAccountsQuery(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { account, subAccounts }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockMainAndSubAccountsQuery = (
  resolver: GraphQLResponseResolver<MainAndSubAccountsQuery, MainAndSubAccountsQueryVariables>,
  options?: RequestHandlerOptions,
) => graphql.query<MainAndSubAccountsQuery, MainAndSubAccountsQueryVariables>('mainAndSubAccounts', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockPendingSubAccountsQuery(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { pendingSubAccounts }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockPendingSubAccountsQuery = (
  resolver: GraphQLResponseResolver<PendingSubAccountsQuery, PendingSubAccountsQueryVariables>,
  options?: RequestHandlerOptions,
) => graphql.query<PendingSubAccountsQuery, PendingSubAccountsQueryVariables>('pendingSubAccounts', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockRejectSubAccountMutation(
 *   ({ query, variables }) => {
 *     const { mainAccountEmail } = variables;
 *     return HttpResponse.json({
 *       data: { rejectSubAccount }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockRejectSubAccountMutation = (
  resolver: GraphQLResponseResolver<RejectSubAccountMutation, RejectSubAccountMutationVariables>,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<RejectSubAccountMutation, RejectSubAccountMutationVariables>('rejectSubAccount', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockSwapOwnerWithSubAccountMutation(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { swapOwnerWithSubAccount }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockSwapOwnerWithSubAccountMutation = (
  resolver: GraphQLResponseResolver<SwapOwnerWithSubAccountMutation, SwapOwnerWithSubAccountMutationVariables>,
  options?: RequestHandlerOptions,
) =>
  graphql.mutation<SwapOwnerWithSubAccountMutation, SwapOwnerWithSubAccountMutationVariables>(
    'swapOwnerWithSubAccount',
    resolver,
    options,
  );

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetProviderQuery(
 *   ({ query, variables }) => {
 *     const { id } = variables;
 *     return HttpResponse.json({
 *       data: { provider }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetProviderQuery = (
  resolver: GraphQLResponseResolver<GetProviderQuery, GetProviderQueryVariables>,
  options?: RequestHandlerOptions,
) => graphql.query<GetProviderQuery, GetProviderQueryVariables>('getProvider', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetProvidersQuery(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { providers }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetProvidersQuery = (
  resolver: GraphQLResponseResolver<GetProvidersQuery, GetProvidersQueryVariables>,
  options?: RequestHandlerOptions,
) => graphql.query<GetProvidersQuery, GetProvidersQueryVariables>('getProviders', resolver, options);

/**
 * @param resolver A function that accepts [resolver arguments](https://mswjs.io/docs/api/graphql#resolver-argument) and must always return the instruction on what to do with the intercepted request. ([see more](https://mswjs.io/docs/concepts/response-resolver#resolver-instructions))
 * @param options Options object to customize the behavior of the mock. ([see more](https://mswjs.io/docs/api/graphql#handler-options))
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetProvidingFormsQuery(
 *   ({ query, variables }) => {
 *     return HttpResponse.json({
 *       data: { providingForms }
 *     })
 *   },
 *   requestOptions
 * )
 */
export const mockGetProvidingFormsQuery = (
  resolver: GraphQLResponseResolver<GetProvidingFormsQuery, GetProvidingFormsQueryVariables>,
  options?: RequestHandlerOptions,
) => graphql.query<GetProvidingFormsQuery, GetProvidingFormsQueryVariables>('getProvidingForms', resolver, options);
